import React, { useRef, useCallback, useEffect, useState } from 'react'

const io = require('socket.io-client')


import './clap.scss'
import '../ui/buttons.scss'

// const SERVER_ADRESS = 'http://localhost:3000'
const SERVER_ADRESS = 'https://therodina-rewire-clap-6px37.ondigitalocean.app'
const CLAP_LIFE = 4500



export default function Clap(props) {

    // console.log(props.room)

    const [cDown, setCDown] = useState(false)
    const [socket, setSocket] = useState(null)
    const [claps, setClaps] = useState([])

    // 
    // Hook for Socket.io messaging
    // 
    useEffect(() => {
        const socket = io(SERVER_ADRESS)
        socket.on('connect', () => {
            // console.log('Conected to clapping')
            setSocket(socket)
        });
        socket.on('clapSend' + props.room, (arg) => {
            // console.log('Getting clap' + props.room)
            // console.log(arg)
            newClap(arg);
        })
        document.addEventListener("keydown", (event) => {event.key === 'c' || event.key === 'C' ? setCDown(true) : null});
        document.addEventListener("keyup", (event) => {event.key === 'c' || event.key === 'C' ? setCDown(false) : null});
        return () => {
            socket?.disconnect();
            document.removeEventListener("keydown", (event) => {event.key === 'c' || event.key === 'C' ? setCDown(true) : null});
            document.removeEventListener("keyup", (event) => {event.key === 'c' || event.key === 'C' ? setCDown(false) : null});
        }
        
    }, [])

    const newClap = (typeOfClap) => {
        setClaps((old) => {
            const date = Date.now();
            const left = (Math.floor(Math.random() * window.innerWidth));
            let symbol;
            if (typeOfClap === 'happyClap'){
                let number = Math.floor(Math.random() * 4);
                if (number == 0){
                    symbol = 'clap-heart1';
                } else if (number== 1){
                    symbol = 'clap-heart2';
                } else if (number == 2){
                    symbol = 'clap-heart3';
                } else{
                    symbol = 'clap-heart4';
                }
            } else {
                symbol = 'clap-carhartt'
            }


            const tmpClap = [date, left, symbol]
            const editedClaps = [...old, tmpClap];
            return editedClaps;
        })
    }

    // 
    // Hook for cleaning que
    //

    useEffect(() => {
        let timeout = null
        const updateClaps = () => {
            timeout = setTimeout(() => {
                if (claps[0] !== undefined && claps[0][0] + CLAP_LIFE < Date.now()) {
                    setClaps((old) => {
                        return [...old.slice(1)]
                    })
                }
                updateClaps()
            }, 500)
        }
        if (claps.length > 30){
            setClaps((old) => {
                return old.slice(old.length-30, old.length-1);
            })
        }

        updateClaps()

        return () => {
            clearTimeout(timeout)
        }
    }, [claps])

    const handleOnClick = () => {

        // 
        // Befeor finish Debounce once per second
        if (socket) {
            if (cDown){
                socket.emit('clapSend' + props.room, 'carharttClap');
            } else {
                socket.emit('clapSend' + props.room, 'happyClap');
            }
            
        }
        if (cDown){
            newClap('carharttClap');
        } else {
            newClap('happyClap');
        }
        
    }

    return (
        <div className="clap" >
            <div >
                {claps.map((clap) => {
                    
                    return (
                        <div key={clap} className='clap-heart' style={{left: clap[1]}}>
                            <div className={clap[2]}></div>
                            
                        </div>
                    )
                })}
            </div>
            <div>
                <div onClick={handleOnClick} className='button-clap-container'>
                <div className={'button-clap'}></div>
                </div>
            </div>
        </div>
    )




}