import React from 'react'
import Close from '../ui/CloseButton'

import classes from "./portals.scss"
import classes2 from '../ui/buttons'
import classes3 from "../window/video-window.scss";


export default function MusicForABusyCity(props) {

     const containerWidth = props.showChat ? ' section-width1' : ' section-width2';
     const marginLeft = props.showChat ? ' section-margin-left1' : ' section-margin-left2';
     const marginRight = props.showChat ? ' section-margin-right1' : ' section-margin-right2';

     return (
          <div className='section-window'>
               <div className={'section-container-portals ' + marginLeft + marginRight + containerWidth}>

                    <div className='portals-close-container'>
                         <Close
                              handleClick={props.handleClose}
                              classes={"button-close-section"}
                         />
                    </div>
                    <div className='portals-leftcolumn'>
                         <div className='portals-title'>
                              Music for a Busy City (online version)
                         </div>

                         <div className='portals-description'>
                         </div>

                         <div className='portals-description2'>
                              This project is hosted on an{' '}
                              <a href='http://www.musicforabusycity.nl/' target='_blank' className='portals-link'>external website.</a>
                         </div>

                         <a href='http://www.musicforabusycity.nl/' target='_blank'>
                              <img src='./images/portals/music-in-the-city2.jpg' className='portals-image'></img>
                         </a>


                    </div>

                    <div className='portals-rightcolumn'>
                         As part of the Rewire 2021 online edition, Rewire presents a new digital interpretation of the Music for a Busy City project planned to take place across The Hague at Rewire 2020.
                         <br></br><br></br>
                         Music for A Busy City features five new pieces of recorded music, each created in response to a specific characteristic location in the city centre. Up to 10 minutes long, these recorded pieces become part of the spaces that inspire them. The project consists of new commissioned compositions by Ain Bailey, Genevieve Murphy, and Pete Harden, Yannis Kyriakides will be presenting two new pieces.
                         <br></br><br></br>
                         Now with this online interpretation audiences are able to access these musical works remotely. Visitors are invited to explore a digital map of these five specific locations in The Hague, with 360-degree photography and video. Expanding our understanding of a musical experience, sound, physical and digital space, Music for a Busy City takes four composers out of the concert hall and into the urban landscape, making music for the spaces we pass every day. Taking this idea further under the current physical restrictions we face in 2021, the project questions our relationship with sound and public space, and what it means for this relationship to be digitally mediated
                    </div>

               </div>
          </div>

     )
}

