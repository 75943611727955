import React from 'react';
import 'regenerator-runtime/runtime';

import firebase from './utils/firebase'
import Close from "./ui/CloseButton";
import CharacterForm from "./ui/CharacterForm";
import FileUploader from "react-firebase-file-uploader";

import classes from "./profile.scss";
import classes2 from "./ui/buttons.scss"


class Profile extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            isUploading: false,
            isProcessing: false,
            canSave: false,
            showLoader: false,

            name: this.props.user.displayName,
            shortName: false,

            avatarURL: this.props.user.photoURL,
            avatarURLChanged: false,
            filename: null,
            filenameThumb: null,

            characterValues: this.props.characterValues,
            characterValuesChanged: false,

        }
    }



    handleUploadStart = () => {
        this.setState({
            isUploading: true,
            showLoader: true,
            canSave: false
        })
    }

    handleUploadError = error => {
        this.setState({ isUploading: false });
        console.error(error);
    };

    handleUploadSuccess = async (filename) => {
        this.setState({
            isUploading: false,
            isProcessing: true
        })

        const filenameThumb = 'thumb_' + filename;
        const path = 'https://firebasestorage.googleapis.com/v0/b/rewire-b9de3.appspot.com/o/' + filenameThumb + '?alt=media';
        const storageRef = firebase.storage().ref().child(filenameThumb);


        // this.setState({ avatarURL: profileImage, isUploading: false })
        this.keepTrying(30, storageRef).then((url) => {
            this.setState({
                avatarURL: url,
                avatarURLChanged: true,
                isUploading: false,
                isProcessing: false,
                canSave: true,
                showLoader: false,

                filenameThumb: filenameThumb,
                filename: filename,
            })
        })
    }


    delay = (t, v) => {
        return new Promise(function (resolve) {
            setTimeout(resolve.bind(null, v), t)
        });
    }
    
    keepTrying = (triesRemaining, storageRef) => {
        if (triesRemaining < 0) {
            return Promise.reject('Server not responding');
        }
    
        return storageRef.getDownloadURL().then((url) => {
            return url;
        }).catch((error) => {
            switch (error.code) {
                case 'storage/object-not-found':
                    return this.delay(1000).then(() => {
                        return this.keepTrying(triesRemaining - 1, storageRef)
                    });
                default:
                    console.log(error);
                    this.setState({
                        showLoader: false,
                        isUploading: false,
                        isProcessing: false,
                    })
                    return Promise.reject(error);
            }
        })
    }
    

    handleNameChange = (name) => {
        this.setState({
            name: name,
            canSave: !this.state.isUploading
        });
    }

    handleCharacterChange = (index, value) => {
        const numberOfPictures = 20;
        let newValues = [];
        newValues = newValues.concat(this.state.characterValues);
        newValues[index] = (newValues[index] + value + numberOfPictures) % numberOfPictures;

        this.setState({
            characterValues: newValues,
            characterValuesChanged: true,
        })
        if (!this.state.isProcessing && !this.state.isUploading) {
            this.setState({
                canSave: true,
            })
        }

    }

    handleOnSave = () => {
        if (this.state.canSave) {
            if (this.state.name !== '') {
                this.setState({
                    shortName: false
                })
                this.props.user.updateProfile({
                    displayName: this.state.name,
                    photoURL: this.state.avatarURL,
                }).then(() => {
                    this.props.handleUserUpdate()
                });
                //poslat novy charakter do app

                this.setState({
                    characterValuesChanged: false,
                    avatarURLChanged: false,
                    canSave: false,
                })
            } else {
                this.setState({
                    shortName: true
                })
            }
        }
    }

    handleOnCancel = () => {
        if (this.state.canSave) {
            if (this.state.avatarURLChanged) {
                firebase.storage().ref().child(this.state.filename).delete().then(() => {
                }).catch((error) => {
                    console.log(error);
                });
                firebase.storage().ref().child(this.state.filenameThumb).delete().then(() => {
                }).catch((error) => {
                    console.log(error);
                });
            }
        }
        this.props.handleProfileClose()
    }

    handleSubmit = (e) => {
        e.preventDefault()
    }

    render() {

        let progress;
        if (this.state.isUploading || this.state.isProcessing) {
            progress = "Processing image";
        } else if (this.state.canSave) {
            progress = "You can save changes";
        }

        return (
            <div>
                <div className={"profile-container"}>

                    <CharacterForm
                    
                        handleCharacterChange={this.handleCharacterChange}
                        values={this.state.characterValues}
                        URL={this.state.avatarURL}
                        handleNameChange={this.handleNameChange}
                        name={this.state.name}
                        logged={true}
                        path={this.state.avatarURL}

                        handleEnter={this.handleOnSave}

                        storageRef={firebase.storage().ref('')}
                        handleUploadStart={this.handleUploadStart}
                        handleUploadError={this.handleUploadError}
                        handleUploadSuccess={this.handleUploadSuccess}
                    />

                    <div className='profile-shortname'>
                        {this.state.shortName ?
                            <div>
                                You need to write a name!
                                                </div>
                            :
                            ""
                        }
                    </div>

                    {this.state.canSave ? <div className="button-square button-darkgray button-save border-dark" onClick={this.handleOnSave}>Save</div>
                        : <div className="button-square button-darkgray button-save border-dark inactive" >Save</div>}


                    <Close handleClick={this.handleOnCancel} classes={"button-close button-right"} />

                </div>

                {this.state.showLoader ?
                    <div className='profile-loader-container'>
                        <div className='profile-loader'>
                        </div>
                    </div>
                    :
                    ""
                }
            </div>

        )
    }
}



export default Profile