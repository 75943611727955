import React from 'react'
import Close from '../ui/CloseButton'

import classes from "./portals.scss"
import classes2 from '../ui/buttons'
import classes3 from "../window/video-window.scss";


export default function NYX(props) {

     const containerWidth = props.showChat ? ' section-width1' : ' section-width2';
     const marginLeft = props.showChat ? ' section-margin-left1' : ' section-margin-left2';
     const marginRight = props.showChat ? ' section-margin-right1' : ' section-margin-right2';

     return (
          <div className='section-window'>
               <div className={'section-container-portals ' + marginLeft + marginRight + containerWidth}>

                    <div className='portals-close-container'>
                         <Close
                              handleClick={props.handleClose}
                              classes={"button-close-section"}
                         />
                    </div>
                    <div className='portals-leftcolumn'>
                         <div className='portals-title'>
                         NYX presents 'Mutualism' feat. MA.MOYO & Nick Cobby
                         </div>

                         <div className='portals-description'>
                         </div>


                         <div className='portals-description2'>
                              This project is hosted on an{' '}
                              <a href='https://mtlsm.co' target='_blank' className='portals-link'>external website.</a>
                         </div>

                         <a href='https://mtlsm.co' target='_blank'>
                              <img src='./images/portals/mutualism.jpg' className='portals-image'></img>
                         </a>

                    </div>

                    <div className='portals-rightcolumn'>
                         Mutualism is a 360 degree immersive audio-visual experience that uses the power of collective female voice to create a metamorphic meditation, embodying our relationship with nature, symbiosis transformation and collective consciousness.
                    </div>

               </div>
          </div>

     )
}

