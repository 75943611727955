import React from 'react'
import Close from '../ui/CloseButton'

import classes from "./portals.scss"
import classes2 from '../ui/buttons'
import classes3 from "../window/video-window.scss";


export default function ShowPonyTheRodina(props) {

     const containerWidth = props.showChat ? ' section-width1' : ' section-width2';
     const marginLeft = props.showChat ? ' section-margin-left1' : ' section-margin-left2';
     const marginRight = props.showChat ? ' section-margin-right1' : ' section-margin-right2';

     return (
          <div className='section-window'>
               <div className={'section-container-portals ' + marginLeft + marginRight + containerWidth}>

                    <div className='portals-close-container'>
                         <Close
                              handleClick={props.handleClose}
                              classes={"button-close-section"}
                         />
                    </div>
                    <div className='portals-leftcolumn'>
                         <div className='portals-title'>
                              Show Pony & The Rodina
                         </div>

                         <div className='portals-description'>
                         </div>

                         <div className='portals-description2'>
                              This project is hosted on an{' '}
                              <a href='https://www.showpony.world/' target='_blank' className='portals-link'>external website.</a>
                         </div>

                         <a href='https://www.showpony.world/' target='_blank'>
                              <img src='./images/portals/pony.jpg' className='portals-image'></img>
                         </a>


                    </div>

                    <div className='portals-rightcolumn'>
                         It took a month's residency at Iceland's Fish Factory in 2019 for Laura Nygren to sketch the outlines of her first Show Pony material. A double bassist, she has performed alongside Damon Albarn, s t a r g a z e, Soap & Skin and Sinfonia Rotterdam, and as Show Pony combines her innovative playing with vocals and synthesizer to explore a vividly personal artistic universe.
                         <br></br>
                         <br></br>
                         At Rewire 2021, the Amsterdam-based, American-born artist will be collaborating with design duo Tereza and Vit Ruller, aka The Rodina. Tereza and Vit run their design studio with an eye towards experimentation and play, spiking their virtual environments with surprising, subversive and thought-provoking elements. The three artists will be presenting a unique 3D online environment to launch Show Pony's debut EP, with exclusive media and live audience participation.
                         </div>

               </div>
          </div>

     )
}

