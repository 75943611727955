import React from 'react';
import { useOffWorldLiveStream } from '../components/OffWorldLiveStreamProvider';
export const useConnectedToStream = () => {
    const stream = useOffWorldLiveStream();
    const [connected, setConnected] = React.useState(stream.isConnected());
    React.useEffect(() => {
        if (!stream) {
            return;
        }
        const onOnline = () => setConnected(true);
        const onOffline = () => setConnected(false);
        stream.onOnline(onOnline);
        stream.onOffline(onOffline);
        return () => {
            stream.off('online', onOnline);
            stream.off('offline', onOffline);
        };
    }, [setConnected, stream]);
    return connected;
};
