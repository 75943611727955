import React, { useState } from 'react'
import Close from '../ui/CloseButton'
import ReactAudioPlayer from 'react-audio-player';


import classes from "./portals.scss"
import classes2 from '../ui/buttons'
import classes3 from "../window/video-window.scss";



export default function SecretPage(props) {

     const [logged, setLogged] = useState(false);
     const [value, setValue] = useState('');
     const [message, setMessage] = useState('');
     const PLACEHOLDER = 'Secret';
     const PASSWORD = 'attune';

     const checkPassword = (event) => {
          if (value.localeCompare(PASSWORD) === 0) {
               setLogged(true);
          } else {
               setValue('');
               setMessage("Wrong Password");
          }
          event.preventDefault();
     }

     const handleChange = (event) => {
          setValue(event.target.value);
     }

     const handleOnCancel = () => {

     }


     return (
          <div className='section-window section-window-secret'>
               <Close
                    handleClick={props.handleClose}
                    classes={"button-close-secret button-right"}
               />
               {logged ?
                    <div className="portals-secret-logged" >

                         <div className='portals-secret-leftcolumn'>

                              <div className='portals-secret-headline'>
                                   AM Kanngieser
                              </div>
                              <div className='portals-line'></div>
                              <div className='portals-secret-description'>
                                   Between Dying and Death there is a Hum.<br></br>
                                   (audio composition, field recording, voice. 21:48min. surround)<br></br>
                                   Content warning: this piece contains mention of death, suicide, grief <br></br>
                              </div>
                              <p>
                                   This piece is an improvised audio diary recorded over several days on the unceded lands of
                                   the Darkinjung people. It was made for the anniversary of my fathers death. While it was
                                   originally imagined as an audio-visual installation, due to COVID-19 it has been re-formatted
                                   for online participation. In order to connect to the physical manifestation it was designed
                                   for you are invited to create an environment conducive to its listening. Please use
                                   headphones if you have them. If possible, find a space for yourself that is dark and warm,
                                   and that will be comfortable for you to sit or lie down. If you have salt and water to hand, fill
                                   a jar with warm water and add salt to place beside you. If you have a candle, please light it
                                   and place it on the other side of you. A written transcript has been provided for you on the right hand side of the screen
                              </p>
                              <div className='portals-secret-player-container'>
                                   <ReactAudioPlayer
                                        src={'https://play.streamnerd.nl/rewire/amkanngieser/icecast.audio'}
                                        autoPlay
                                        controls
                                        muted={props.muted || props.inWindow}
                                        className='portals-secret-player'
                                   />
                              </div>
                              <img src='./images/portals/am-kanngieser2.jpg' className='portals-secret-image'></img>

                         </div>

                         <p className='portals-secret-rightcolumn'>
                              Transcription of Audio<br></br><br></br>
                              I wonder what the Darkinjung people thought when they saw the colonizers. I wonder what
                              the colonizers thought when they saw this land. All of the things that these waves have
                              seen. When I was little, I was terrified of the land. I used to run home at night as fast as I
                              could, spiderwebs breaking across my face, in tears. There were no street lights where I
                              lived, just dirt tracks through the bush. I was terrified of what was in that bush, of what was
                              in that dark. There were so many places around near where I lived that wasn&#39;t good for me
                              to be. And I always wondered what had happened there, what spirits were showing
                              themselves to me.<br></br><br></br>
                              A few weeks ago I was on this land, surrounded by these spirits. I walked through the night,
                              slowly now, spider webs still snapping against my skin as I mourned a gathering that never
                              was. Today I lie here, my bones filled with the weight of memories and ghosts. In one hand I
                              hold a paua shell, its insides a light pearlescence. In the other, a jar of salt water. Today is
                              the day of my fathers death. My father was a boat builder, a navigator and a sailor. He died
                              suddenly, when he was still young. He bore many troubles I suppose it would be right to say.
                              He spent his life on the ocean. He drowned his desires there and there he came to lay,
                              forever in its waters.<br></br><br></br>

                              I have always been attuned to the energy that enfolds me awake and in dreams. I had
                              hoped that I could bring this to you in a room together, bathed in the smoke of the candle I
                              have burning for him. I wanted us to sit beside one another in reverence of oceanic time. Of
                              cyclical time. On this land, in this ocean, the times of life and death meet and move in
                              circles. In these cycles there is no possession, nothing can be taken by force, there is no
                              force capable of diminishing what and who was always here. Nothing is lost, nothing is ever
                              entirely gone. In this place, I can only invoke who and what names me. The ocean cradles
                              my body, pulls me under in its currents. I wanted to surround you in this ocean, in these
                              rocks and seaweed and brine.<br></br><br></br>

                              I imagined my breath would be close to your ears, so that you could feel it on your skin. In a
                              room together where we could grieve in the dark. Instead I come to you like this over
                              distance, my words without the touch of exhalation. So I invite you to make this room for
                              yourself with me, wherever you are. Take some salt and immerse it in water, place this by
                              your side. Find somewhere that is dark and warm where you can sit or lie comfortably. If
                              you have a candle, please light it for your own ones that are not with you now.<br></br><br></br>

                              I will tell you about the last time I truly felt my father with me. Over several days I went to
                              where his ashes came to rest. Each day I asked the land and the ocean if I may sit amongst it
                              with my grief. Each day I laid something into the shallows, to remember him by. It began on
                              a Tuesday.<br></br><br></br>

                              About 500 meters offshore there&#39;s a reef and the waves are going in all directions, breaking
                              against each other. Waves coming from the right and the left, smashing together in the<br></br><br></br>

                              middle in a huge shower of water. It&#39;s grey above me. It&#39;s not cold. There&#39;s no one else here.
                              In front of me, across this massive rock platform, are hundreds of seagulls and they jump up
                              and fly every time the waves come too close, the spray comes too close. They just stand
                              there waiting. The spray when the waves hit the rock is like a spectre moving across the rock
                              platform. The mist of the spray.<br></br><br></br>
                              I remember this ocean. I remember this temperament of ocean from when I was young. I
                              remember sailing on this ocean, this grey ocean, this ocean that gathers itself like this,
                              curled waves, and they&#39;re very close together, choppy. I remember sailing on this kind of
                              ocean and how sick it would make me because there was no way to catch your footing in
                              waves that go in all directions. It&#39;s like waves upon waves upon waves upon waves. Today
                              would be the day you think the weather would be the closest to grief, but it&#39;s not. This isn&#39;t
                              grieving weather. This is something else. There&#39;s too much electricity in this weather.
                              There&#39;s too much energy, too much force.<br></br><br></br>

                              I&#39;m sitting against these old sandstone curves and jags that make up the geology here. The
                              stone is white and orange, brown, ochre, red, and it&#39;s being carved and hollowed out by the
                              wind. There are parts of it that are full of holes, very intricate kind of lattice-like holes,
                              where the wind and the water has rushed through. The sandstone forms these kinds of rings
                              or tide marks in circles and a lot of it is worn smooth, but where I&#39;m sitting in this slight
                              basin, I&#39;m very exposed to the wind, and I&#39;m facing the ocean so that I can see the waves
                              hitting up against the rock platform in front of me. It&#39;s very hot today. The sun is very bright
                              and I can feel it burning.<br></br><br></br>
                              Every time I come here, I sit and I ask permission to be here. I talk to these waves and this
                              wind, this place. I talk to the spirits and the people who are here, these sovereign stolen
                              lands. I ask if it&#39;s okay to be here. For me, this sea holds so much sadness. This sea holds a
                              lifetime&#39;s worth of sadness. I was born on a boat, and this sea was the first thing I ever
                              heard. This is the sea where my dad&#39;s ashes went. This is the sea that I almost drowned in so
                              many times. When I was a kid, I used to go swimming, even when the surf was really big and
                              I remember one time being dragged out by a current and dumped so badly against the sand,
                              against the sandbank that I didn&#39;t know which way was up and I almost died then. And I&#39;ll
                              never forget that feeling of looking around and having no idea where I was. There&#39;s a lot
                              between dying and death. There is a lot of life lived on an edge. An edge is not thin. An edge
                              can last a lifetime. That threshold ... there&#39;s a lot of space on that threshold.
                              I&#39;ve come here today a little bit later in the day. The sea is calm today and it&#39;s late in the
                              afternoon, so the sun isn&#39;t as bright. It&#39;s not as hot. The tide is a lot lower. Everything just
                              feels calmer. I&#39;m sitting somewhere different today. I&#39;m sitting on a grassy verge leading up
                              to the walk along the escarpment. I can hear crickets around me, very faintly, and my body
                              is being buffered by a gentle wind. I can hear the ocean, but it&#39;s quieter than other days. The
                              ocean is more of a blue-green colour. The sky is clear with a few clouds, but not many. What
                              I love about being in this ocean is that all the salt makes everything rise to the surface. Salt
                              pulls out moisture. Your mouth is all pickled with it. Salt from your body returns to the sea. I
                              love that feeling of being pickled by the salt in the sea. In that brine. The Pacific is very salty.
                              When you come out, you&#39;re covered in it. It crackles against your skin.<br></br><br></br>


                              I&#39;ve been thinking a lot about how my body carries grief and what grief feels like. Grief feels
                              heavy. It feels like sludge. It feels like every day I&#39;m dragging myself through everything and
                              everything is far away somehow, like my body is present, but also not present. There&#39;s a real
                              warmth to disassociation. It feels like a suspension, a sort of floating in between states. The
                              thing when you&#39;ve felt so much grief in a life, the thing is that it just becomes such a
                              foundational part of how you experience everything, how you exist. I think what I like about
                              the ocean is its circularity,  rhythms, its tides, because those feel to me like my grief. It just
                              keeps coming around, waxing and waning, ebbing and flowing. It breathes in and it breathes
                              out.<br></br><br></br>
                              And I wonder if there&#39;s something really important in accepting grief in this way as
                              inevitable. Why should we not accept grief? Death, illness, sickness, sadness, desperation,
                              fear, loss, we all feel those things. What is a life without those things? But grief just never
                              goes away. So maybe the ocean helps me to think of that as a coming and going that&#39;s never
                              ending.<br></br><br></br>
                              The first sound I ever heard was the gurgling of this ocean. Every night I went to sleep with
                              these waves slapping against the hull of the boat. There&#39;s a long way between dying and
                              death. There&#39;s a lot of journeys that I&#39;ve been on. And that&#39;s been my whole life, as a hum, a
                              constant, ongoing, always with me, the sound in my ears, in my body, in my skin, in my
                              stomach. The hum of these waves, the hum of the tides, the hum of the currents, stones
                              washed down. I think what this ocean does is bring everything through time to one present
                              point for me. Every time I&#39;m on this ocean, everything that has ever happened to you
                              collapses together, and it&#39;s all in this moment again. There is no past, and there is no future
                              with this ocean. Everything is here. It&#39;s hard to even talk about those memories because I
                              can&#39;t pull them apart. They don&#39;t begin and end. When I watch the waves, I&#39;m reminded of
                              what gets sucked out underneath into the currents, how deep the ocean goes, things get
                              pulled down. When the weather&#39;s like this, they pop back up, things that have been lodged
                              in reefs and corals, in stones, in the deep, quiet waters. They get thrown back up by the
                              waves. There&#39;s always something being un-submerged, things found that come from the
                              ocean.<br></br><br></br>

                              Between dying and death there is a hum. The wind vibrates with it, my body is only a
                              weathervane. I&#39;m very certain that I will drown. I feel like I came from this place and this is
                              the place that I will go back to. There are times to let go and there are times to hold on and
                              sometimes, there is nothing to tell them apart.<br></br><br></br>

                              The ocean holds everything, though. It holds bodies. It holds bones. It holds sadness. It holds
                              joy. It holds everything beyond anything I could even imagine. It holds things that I will
                              never even know, and that is what I love. Those creatures that are not for me and that I will
                              never, ever even know of bring me such relief. Things not to know, things that I don&#39;t need
                              to know, that are not mine to know. What a freedom.<br></br><br></br>

                         </p>
                    </div>
                    :
                    <div>
                         <form onSubmit={checkPassword} className={"portals-secret-form"}>

                              <label >
                                   <input type="text" value={value} onChange={handleChange} placeholder={PLACEHOLDER} className={"portals-secret-form-label"} />
                              </label>

                              <input type="submit" value="ENTER" className="button-square button-white button-secret border" />
                              <div className='portals-secret-wrong'>{message}</div>
                         </form>

                    </div>

               }

          </div>
     )

}

