import React, { useState, useEffect } from 'react'
import firebase from '../utils/firebase'
import ReactPlayer from 'react-player';
import VideoOnDemand from './VideoOnDemand';

import Close from '../ui/CloseButton'

import classes from "./videoondemand.scss";
import classes2 from '../ui/buttons'
import classes3 from "../window/video-window.scss";


export default function VideoDialog(props) {

     const text = ['Thursday 6 May', 'Friday 7 May', 'Saturday 8 May', 'Sunday 9 May',];
     const days = ['thu6', 'fri7', 'sat8', 'sun9'];
     const [index, setIndex] = useState(props.openedDialog - 6);
     const [loader, setLoader] = useState(true);

     const [vod, setVod] = useState(
          {
               thu6: [],
               fri7: [],
               sat8: [],
               sun9: [],
          }
     )

     useEffect(() => {

          let vodRef = firebase.database().ref("vod/");
          vodRef.on("value", (snapshot) => { // to je callback vzdy se pusti kdyz neco zmenis v DB
               let newVod = {
                    thu6: JSON.parse(snapshot.val().thu6),
                    fri7: JSON.parse(snapshot.val().fri7),
                    sat8: JSON.parse(snapshot.val().sat8),
                    sun9: JSON.parse(snapshot.val().sun9),
               }
               setVod(newVod);
               setLoader(false);
          })
     }, [])

     const openVideoOnDemand = (array) => {
          props.handleOpenedSectionVod(array, text[props.openedDialog - 6])
     }

     const play = (
          <svg
               xmlns="http://www.w3.org/2000/svg"
               viewBox="0 0 77.87 77.87"
          >
               <g data-name="Vrstva 2">
                    <circle
                         cx={38.94}
                         cy={38.94}
                         r={37.94}
                         fill="none"
                         stroke="#fff"
                         strokeMiterlimit={10}
                         strokeWidth={2}
                    />
                    <path
                         d="M56.14 36.56L31.89 16.85A3 3 0 0027 19.18V58.6a3 3 0 004.89 2.33l24.25-19.71a3 3 0 000-4.66z"
                         fill="#fff"
                    />
               </g>
          </svg>
     )

     return (
          <div className='video-container'>
               {loader ?
                    ""
                    :
                    <div>
                         <Close
                              handleClick={props.handleClose}
                              classes={"button-close button-right"}
                         />

                         <div className='video-scroll'>
                              <div className='video-title-container' >
                                   <div className='video-title'>{text[props.openedDialog - 6]}</div>
                                   <div className='video-subtitle'>Watch back a selection of performances that live premiered on {text[props.openedDialog - 6]}</div>
                              </div>
                              <div className='video-content'>
                                   {vod[days[index]].map((array) =>
                                        <div key={array.name}>
                                             <div className='video-tile'>
                                                  <div className='video-player-container' onClick={() => openVideoOnDemand(array)}>
                                                       {/* <div className='video-player-play'>
                                                            {play}
                                                       </div> */}

                                                       <ReactPlayer
                                                            className='video-player'
                                                            url={array.status}
                                                            muted={props.muted}
                                                            playing={false}
                                                            width='105%'
                                                            height='105%'

                                                            playsinline={true}
                                                            config={{
                                                                 file: {
                                                                      attributes: { playsInline: true },
                                                                      hlsOptions: {
                                                                           // debug: true,
                                                                      },
                                                                      hlsVersion: '1.0.2'
                                                                 }
                                                            }}

                                                       />
                                                  </div>
                                                  <div className='video-tile-text'>
                                                       {array.name}
                                                  </div>

                                             </div>
                                        </div>

                                   )}
                              </div>
                         </div>
                    </div>
               }

          </div>
     )
}

