import React, { useState, useEffect } from 'react'

import Close from '../ui/CloseButton'

import classes from './welcome.scss'
import classes2 from './overview.scss'
import classes3 from '../ui/buttons.scss'

export default function WelcomeMessage(props) {

     return (
          <div className="popup-container">
               <Close handleClick={props.handleClick} classes={"button-close button-right"} />

               <div className='popup-scroll'>
                    <div className="popup-headline">
                         INTRODUCTION
                    </div>
                    <div className="popup-text">
                         <span className='popup-bold'>We are thrilled to welcome you to Rewire 2021, it took some time and adjustments but here we are: the tenth edition of Rewire is finally here and it’s fully online!</span>
                         <br></br><br></br>
                         Embracing experimentation like never before, Rewire 2021 will aim to highlight the different ways that artists have been creating and presenting their work under physical restrictions.
                         Rewire takes on the challenge of connecting artists with each other, and their audiences, digitally. Staged across two video channels, a radio channel, seven differents ‘portals’, Rewire brings together artists and audience to collaborate, create and share live concerts, audiovisual and interactive experiences, commissioned works, exclusive film screenings, radio shows, talks, workshops and much more.
                         <br></br><br></br>
                         Rewire 2021 offers a moment of pause to reflect on our contemporary arrangements, and reorientate ourselves. How can a festival still be meaningful in times of large-scale isolation in an industry that fed off of liveness and proximity? The festival theme (Re)setting responds to processes of change and adaptation through a number of talks, workshops, meditations and performances. (Re)setting folds in our current context of the pandemic and how it has affected all aspects of our lives, including our health and our ability to create and connect to one another, as well as exploring the on-going process of Rewire manifesting online. It reflects on this new era of physical distance, insecurity and loneliness and at the same time show us the possibilities that arise from this.
                         <br></br><br></br>
                         The online edition of Rewire welcomes a series of remote residencies: a large number of commissioned projects from artists all around the world, connected remotely, to explore new collaborative practices both online and in isolation. Rewire has invited musical artists to collaborate on new pieces: <span className='popup-bold'>claire rousay & Morita Vargas, Elza Soares & jaimie branch, Robert Aiki Aubrey Lowe & Zeena Parkins,  Valentina Magaletti & Maria Chavez, Yasuaki Shimizu & Kara-Lis Coverdale</span> and musical artists to work with filmmakers or video artists to produce new audiovisual works: <span className='popup-bold'>CS + Kreme & Alessandra Leone, Duma & Jesse Kanda, Dreamcrusher & Andrés Baron, KMRU & Donna Verheijden, Maral & Brenna Murphy.</span>
                         <br></br><br></br>
                         In addition to musical and audiovisual collaborations, Rewire 2021 will see several projects in which software developers, game designers and creative coders team up with musicians and composers to creatively utilise the interactive possibilities of going online. <span className='popup-bold'>GLOR1A</span> is working alongside VR developer and visual artist <span className='popup-bold'>alpha_rats</span> on SWARM 2.0, an interactive prototype game that fuses digital art and live performance. <span className='popup-bold'>Show Pony</span> presents her collaboration with experimental design duo <span className='popup-bold'>The Rodina</span> as they unveil a unique 3D online environment, with new music and live audience participation. <span className='popup-bold'>MSHR</span> presents an interactive audiovisual composition that will be presented as a navigable virtual space.
                         <span className='popup-bold'> Meuko! Meuko!</span> collaborates with network embedded art collective <span className='popup-bold'>NAXS Corp</span>. on ‘0 Selves’, an explorative, interactive audio-visual journey. Audiences must wander through the immense virtual world, to discover live sets and motion-captured virtual performances. <span className='popup-bold'>Pussykrew</span> presents their immersive gaming odyssey ‘Serpent of Self’, an interactive film written in collaboration with AI. <span className='popup-bold'>Clifford Sage</span> presents the newest iteration of his ever evolving audiovisual project ‘Tuner’ in which the audience can interact live. <span className='popup-bold'>Pierce Warnecke & Matthew Biederman</span> are presenting ‘Somnifacient Signals’, an urgent interactive project exploring the possibility of connecting with audiences at the time of heightened physical restrictions.
                         <br></br><br></br>
                         Rewire is teaming up with virtual club platform Club Qu, presenting sets by artists from Brazil, China, United Kingdom, Jordan, and Mexico; <span className='popup-bold'>Badsista, Hyph11E, Tzusing, BFTT, Bashar Suleiman,</span> and <span className='popup-bold'>Siete Catorce</span>. They will perform in the community-driven online environment on the Saturday night of the festival. 
                         <br></br><br></br>                 
                         The programme consists of multiple specially recorded performances from artists far and wide including <span className='popup-bold'>Kate NV, OOIOO, Smerz, FUJI|||||||||||TA, Galya Bisengalieva, Carl Gari & Abdullah Miniawy, Levy Lorenzo, Holland Andrews, Satomimagae, Masma Dream World,</span> and many more.
                         <br></br><br></br>       
                         Whilst presenting the 2021 festival online has opened up possibilities for collaborations and projects from all over the world, the programme also features a number of performances from acts based close to Rewire’s home. Set in iconic venues across The Hague, such as the Kunstmuseum, Electriciteitsfabriek and the Koninklijke Schouwburg, these performances include <span className='popup-bold'>Able Noise, Bby Eco, Genevieve Murphy, Leo Svirsky & The River Without Banks</span>, and many more highlights. Also set close to home is ‘Manicurism’ by <span className='popup-bold'>No Plexus</span>, a live performance / visual album featuring spectacular drone shots from otherworldly locations; Celestial Vault at Kijkduin, Riff in Dronten and a bonsai farm in Oudenbosch.
                         <br></br><br></br>       
                         With music presentations going online we’ve seen a tendency to lean heavily into the visual possibilities. Increasingly, we felt the need to give space for sound and listening projects that take us away from our screens. So Rewire 2021 will see the introduction of a radio channel where sound and transmission artworks that experiment with radio as an art form will be presented. 
                         <br></br><br></br>       
                         Looking for even more input? Check out these <a href='https://www.rewirefestival.nl/features'>features</a> for more background information about the programme.
                         <br></br><br></br> 
                         Join the conversation and chat with fellow festival visitors and artists via our Rewire Discord channel <a href='https://www.rewirefestival.nl/news/sign-up-to-rewires-discord-channel-to-chat-with-visitors--artists'>here</a>.
                         <br></br><br></br> 
                         We wish you all an incredible Rewire 2021 and see you in the chat!
                    </div>

               </div>
          </div>
     )
}

