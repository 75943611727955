import React from 'react';
import { useCoolingDown } from '../hooks/useCoolingDown';
import { useReactionPercentage } from '../hooks/useReactionPercentage';
import { useSendReaction } from '../hooks/useSendReaction';
import { useTriggerState } from '../hooks/useTriggerState';
export const ReactionButton = ({ id, className, style, reactionName, children, percentageComponent, }) => {
    const isCoolingDown = useCoolingDown(reactionName);
    const percentComplete = useReactionPercentage(reactionName);
    const sendReaction = useSendReaction();
    const triggering = useTriggerState(reactionName);
    const onClick = React.useCallback(() => sendReaction(reactionName), [
        reactionName,
        sendReaction,
    ]);
    const percentBlock = React.useMemo(() => {
        if (!percentageComponent) {
            return (React.createElement("span", { style: {
                    position: 'absolute',
                    background: '#005281',
                    top: 0,
                    left: 0,
                    height: '100%',
                    zIndex: 0,
                    width: `${percentComplete}%`,
                } }));
        }
        return percentageComponent(percentComplete);
    }, [percentageComponent, percentComplete]);
    return (React.createElement("button", { id: id, style: style ? style : { position: 'relative' }, className: `${className} ${triggering ? 'reaction-triggered' : 'reaction-inactive'}`, type: "button", onClick: onClick, disabled: isCoolingDown },
        percentBlock,
        children ? children : reactionName));
};
