import React , { Suspense } from 'react'

import classes from "./buttons.scss";

function MuteButton (props) {
      return (
        <div className={"button-square button-white border" + (props.inWindow ? ' button-mute-inwindow' : ' button-mute')} onClick={props.handleClick}>
            {props.text}
        </div>
      )
  }
  
  export default MuteButton