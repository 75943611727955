import React , { Suspense } from 'react'

// import classes from "../pages/about.scss";
// import classes2 from "./buttons.scss";

function Chat(props) {
      return (
        <React.Fragment>
            <iframe src="https://titanembeds.com/embed/832565772953321503?css=243&defaultchannel=835897815624056853" height="100%" width="100%" frameBorder="0" ></iframe>
        </React.Fragment>
      )
  }
  
  
export default Chat