import classes from '../pages/timetable.scss'
import classes2 from './prismic.scss'
import React, { useRef, useCallback, useEffect, useState } from 'react'
import Prismic from '@prismicio/client'
import Close from "../ui/CloseButton";

import { RichText } from 'prismic-reactjs';
import { array } from 'prop-types';

const apiEndpoint = 'https://rewirefestival.cdn.prismic.io/api/v2'


export default function PrismicTimetable(props) {

    const [title, setTitle] = useState([]);
    const [subtitle, setSubtitle] = useState([]);
    const [body, setBody] = useState([]);
    const [links, setLinks] = useState([]);
    const [image, setImage] = useState([]);
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        setLoader(true);
        async function fetchData() {
            const type = 'artist';
            const type2 = 'news';
            Prismic.client(apiEndpoint).getApi().then(api => {
                if (props.uid !== 'rewire-partners-with-het-nieuwe-instituut-for-symposium' && props.uid !== 'music-for-a-busy-city-goes-online') {
                    return api.getByUID(type, props.uid, { lang: 'en-gb', fetchLinks: ['artist.title', 'artist.tags', 'artist.image'] })
                } else {
                    return api.getByUID(type2, props.uid, { lang: 'en-gb', fetchLinks: ['artist.title', 'artist.tags', 'artist.image'] })
                }

            }).then(res => {
                setImage(res.data.image)
                setTitle(res.data.title)
                setSubtitle(res.data.subtitle)
                // console.log(res.data.body[0].text)
                setBody(res.data.body);
                if (res.data.links !== undefined){
                    setLinks(res.data.links);
                }
                setLoader(false);
            })
        }
        fetchData()
    }, [props.uid])
    // Custom hooks

    return (
        <div className='prismic-container'>
            {loader ?
                <div className='prismic-loading-wrapper'>
                    <div className='prismic-loader'> </div>
                    <div className='prismic-loading-text'>Loading</div>
                </div>
                :
                <div>
                    <Close handleClick={props.handleClick} classes={"button-close button-right"} />

                    <div className='prismic-content'>
                        <div className='prismic-title'>{title}</div>
                        

                        {subtitle !== [] && subtitle !== null ?
                            <div>
                                <div className='prismic-subtitle'>{subtitle}</div>
                            </div>
                            :
                            ''
                        }
                        <div className='prismic-line'></div>

                        <div className='prismic-text'>
                            {RichText.render(body)}
                            <br></br>
                            {links !== [] ? links.map((array) =>
                                <div key={array.name} >
                                    <a href={array.link.url} target='_blank' className='prismic-links'>{array.name} </a>
                                </div>

                            ) : ''}
                        </div>
                        <div className='prismic-links'>
                            
                        </div>
                        <div className='prismic-image-container' >
                            <img src={image.url} alt={image.alt} className='prismic-image' />
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

