import classs from './avatars.scss'
import React, { useRef, useCallback, useEffect, useState } from 'react'
import useAvatars from './useAvatars'


export default function Avatars(props) {
    const ref = useRef(null)
    const avatars = useAvatars(props.user, props.scroll)

    // const [showAvatars, setShowAvatars] = useState(props.showAvatars);
    // const [inWindow, setInWindow] = useState(props.inWindow);

    // useEffect(() => {
    //     setShowAvatars(props.showAvatars);
    //     setInWindow(props.inWindow);
    // }, [props.showAvatars, props.inWindow])
    // Custom hooks

    return (
        <div>
            {(props.showAvatars && !props.inWindow &&  window.innerWidth > 600) ?
                <div ref={ref}>
                    {avatars?.map((el) => {
                        return (
                            <div
                                style={{ transform: `translate3d(${el.value.position.px}px,${el.value.position.py}px,0)` }}
                                className="avatar"
                                key={el.key}
                            >
                                <div>{el.value.name !== undefined && el.value.name.length <= 20 ? el.value.name : el.value.name.substring(0,19)}</div>
                                <img src={el.value.profileURL} />
                            </div>
                        )
                    })}
                </div>
                :
                ""
            }
        </div>
    )
}