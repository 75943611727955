import React, { useRef, useCallback, useEffect, useState } from 'react';

import classes from "./buttons.scss"

 function SliderArrows(props) {
     return (
          <div>

               <div className={"button-section-arrow button-section-arrow-left"} onClick={props.handleLeftClick}>
                    ←
                </div>

               {props.showChat ?
                    <div className={"button-section-arrow button-section-arrow-right-c"} onClick={props.handleRightClick}>
                         →
                    </div>
                    :
                    <div className={"button-section-arrow button-section-arrow-right"} onClick={props.handleRightClick}>
                         →
                    </div>
               }

          </div>
     )
}

export default React.memo(SliderArrows)