import React , { Suspense } from 'react'

import classes2 from "./buttons.scss";

function TimetableButton (props) {
      return (
        <div className="button-square button-white button-timetable border" onClick={props.handleClick}>
            Timetable
        </div>
      )
  }
  
  
  export default TimetableButton