var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { LiveStreamEnded, LiveStreamInProgress, LiveStreamWaitingForStart, } from './LiveStreamStates';
import { idleWatcher } from './lib/idleWatcher';
export const InitialStateType = 'INITIAL_STATE';
export const StateChangedType = 'STATE_CHANGED';
export const ReactionClickType = 'REACTION_CLICK';
export const PercentCompleteType = 'PERCENT_COMPLETE';
export const ViewerChangedType = 'VIEWER_CHANGED';
export const CoolDownStartType = 'COOL_DOWN_START';
export const CoolDownEndType = 'COOL_DOWN_END';
const [hiddenKey, visibilityChangeKey] = (() => {
    if (typeof document.hidden !== 'undefined') {
        return ['hidden', 'visibilitychange'];
    }
    if (typeof document.msHidden !== 'undefined') {
        return ['msHidden', 'msvisibilitychange'];
    }
    if (typeof document.webkitHidden) {
        return ['webkitHidden', 'webkitvisibilitychange'];
    }
    return [undefined, undefined];
})();
/**
 * Off World Live Stream Client Library
 *
 * A frontend library for connecting to the Off World Live Stream API
 * via websockets
 *
 * @example
 * ```typescript
 * 		const config = {
 * 			liveStreamSlug : 'your-live-stream-name',
 * 			apiRoot = 'https://api.offworld.live',
 * 			clientAPIKey = 'your-api-key,
 * 			reactionThrottleTime = 1000,
 * 		};
 *
 * 		// instantiate the class:
 *
 * 		const api = new OffWorldLiveStream(config);
 *
 *
 * 		// create a callback for when
 * 		// the liveStream state changes
 * 		api.onStateChange((newState) => {
 * 		switch(newState) {
 * 			case OffWorldLiveStream.LiveStreamWaitingForStart:
 * 			 // waiting for start
 * 				document.body.setAttribute("class", "waiting-to-start")
 * 			 return
 *
 * 			case OffWorldLiveStream.LiveStreamInProgress:
 * 				// waiting for start
 * 				document.body.setAttribute("class", "in-progress")
 * 			 return
 * 			case OffWorldLiveStream.LiveStreamEnded:
 * 				// waiting for start
 * 				document.body.setAttribute("class", "ended")
 * 				return
 * 		}
 * 		});
 *
 * 		api.onPercentCompleteChange((reactionName, percentComplete)=> {
 * 		// do something with percent complete
 * 		});
 *
 * 		api.onViewerCountChange((viewerCount)=> {
 * 		  // n.b. this is the total
 * 		  // do something with the viewer count
 * 		});
 *
 * 		// Sometimes, after a reaction is triggered there is a cooling-off
 * 		// period when clicks to that reaction will have no effect
 * 		api.onCoolDownChange((reactionName, isCoolingDown) => {
 * 		});
 *
 * 		api.onOnline(() => {
 * 		// e.g. show something
 * 		})
 *
 * 		api.onOffline(() => {
 * 		// e.g. hide something
 * 		})
 *
 * 		api
 * 		.connect()
 * 		.then(() => {
 * 				// now you can start sending reactions
 * 				api.sendReaction('apple');
 * 				// or you can bind them to your onClick functions
 * 				document.getElementById('apple-button').addEventListener('click', () => {
 * 						api.sendReaction('apple');
 * 				});
 * 		});
 * ```
 */
export class OffWorldLiveStream {
    constructor(props) {
        this.internalLiveState = {
            reactions: {},
            state: LiveStreamWaitingForStart,
            viewers: 0,
        };
        this.connected = false;
        this.disconnecting = false;
        this.onChangeListeners = new Set();
        this.stateChangeListeners = new Set();
        this.percentChangeListeners = new Set();
        this.viewerChangeListeners = new Set();
        this.coolDownChangeListeners = new Set();
        this.onlineListeners = new Set();
        this.offlineListeners = new Set();
        this.connectionRetries = 40;
        this.currentRetries = 0;
        this.initalFetch = false;
        this.connection = undefined;
        this.fillingQueue = false;
        this.reactionQueue = [];
        this.intervalHandle = 0;
        this.boundOnVisibility = false;
        this.boundIdleWatcher = false;
        this.idle = false;
        this.debugMode = false;
        this.disabled = false;
        this.logErrors = true;
        const { liveStreamSlug = 'your-slug', apiRoot = 'https://api.offworld.live', clientAPIKey = '--', reactionThrottleTime = 1000, debugMode = false, disabled = false, } = props;
        this.disabled = disabled;
        this.debugMode = debugMode;
        this.debugLog('Initialising OffWorldLiveStream');
        this.liveStreamSlug = liveStreamSlug;
        this.apiRoot = apiRoot;
        this.clientAPIKey = clientAPIKey;
        this.reactionThrottleTime = reactionThrottleTime;
    }
    get state() {
        return this.liveState;
    }
    set state(_) {
        throw Error('State is readonly. It cannot be set');
    }
    get liveState() {
        return this.internalLiveState;
    }
    set liveState(s) {
        if (s === this.internalLiveState) {
            return;
        }
        this.internalLiveState = s;
        this.onChangeListeners.forEach((i) => i(s));
    }
    /**
     * Are we connected to the api
     * @return boolean
     */
    isConnected() {
        return this.connected;
    }
    /**
     * Starts the connection and returns a promise to tell you when you're ready
     * @returns Promise that resolves / rejects when connected to the server
     */
    connect() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.disabled) {
                return;
            }
            if (!this.boundOnVisibility) {
                this.bindOnVisibility();
                this.boundOnVisibility = true;
            }
            this.bindIdleWatcher();
            window.clearInterval(this.intervalHandle);
            if (!this.initalFetch) {
                // make call to root to enable CORS in browser
                yield fetch(`${this.apiRoot}/`, { mode: 'cors' });
                this.initalFetch = true;
            }
            this.debugLog('Initialising websocket connection');
            const connection = this.getWSConnection(`${this.apiRoot.replace(/^http/, 'ws')}/ws/${this.liveStreamSlug}/${this.clientAPIKey}`);
            this.connection = connection;
            connection.onclose = () => {
                this.debugLog('Closing websockets connection');
                this.dispatchConnectionChange(false);
                if (this.disconnecting) {
                    this.disconnecting = false;
                    return;
                }
                this.backoffRetry();
            };
            connection.onerror = (err) => {
                this.debugError(err);
                this.connected = false;
                this.dispatchConnectionChange(false);
                try {
                    connection.close();
                }
                catch (e) {
                    console.info('failed to close', e);
                }
                this.handleConnectionError(err);
            };
            connection.onmessage = (e) => this.onMessage(e);
            // wait for the connection to load
            yield new Promise((res) => {
                connection.onopen = () => {
                    this.connected = true;
                    this.dispatchConnectionChange(true);
                    res(undefined);
                };
            });
            this.intervalHandle = window.setInterval(() => this.checkQueue(), this.reactionThrottleTime);
        });
    }
    /**
     * @param string the name of the reaction being sent
     * @throws Error when not connected
     */
    sendReaction(reaction) {
        const r = this.liveState.reactions[reaction];
        if (r && r.coolingDown) {
            return;
        }
        if (!this.fillingQueue) {
            this.fillingQueue = true;
            // if this is the first in the time period,
            // send out this reaction
            if (!this.connected) {
                this.reactionQueue.push(reaction);
            }
            try {
                this.send({
                    count: 1,
                    reaction,
                    type: ReactionClickType,
                });
            }
            catch (e) {
                this.handleConnectionError(e);
            }
            return;
        }
        this.reactionQueue.push(reaction);
    }
    /**
     * Executes callback when any data is changed
     * @param function(newState:LiveState) void
     */
    onChange(cb) {
        this.onChangeListeners.add(cb);
    }
    /**
     * Executes callback when the state changes
     * @param function(newState:string) void
     */
    onStateChange(cb) {
        this.stateChangeListeners.add(cb);
    }
    /**
     * Executes callback when the percent complete
     * of a particular reaction changes
     * @param function(reactionName: string, percentComplete:number) void
     */
    onPercentCompleteChange(cb) {
        this.percentChangeListeners.add(cb);
    }
    /**
     * Executes callback when the active viewers changes
     * @param function(viewers:number) void
     */
    onViewerCountChange(cb) {
        this.viewerChangeListeners.add(cb);
    }
    /**
     * Executes callback when the `CoolDown` state for a given
     * reaction changes.
     * @param function(reactionName: string, coolingDown: boolean) void;
     */
    onCoolDownChange(cb) {
        this.coolDownChangeListeners.add(cb);
    }
    /**
     * Executes callback when this goes offline
     */
    onOffline(cb) {
        this.offlineListeners.add(cb);
    }
    /**
     * Executes callback when this goes online
     */
    onOnline(cb) {
        this.onlineListeners.add(cb);
    }
    /**
     * Unbinds listeners for given event
     * @param cb
     */
    off(eventName, cb) {
        switch (eventName) {
            case 'change':
                this.onChangeListeners.delete(cb);
                return;
            case 'stateChange':
                this.stateChangeListeners.delete(cb);
                return;
            case 'viewerCountChange':
                this.viewerChangeListeners.delete(cb);
                return;
            case 'percentCompleteChange':
                this.percentChangeListeners.delete(cb);
                return;
            case 'coolDownChange':
                this.coolDownChangeListeners.delete(cb);
                return;
            case 'online':
                this.onlineListeners.delete(cb);
                return;
            case 'offline':
                this.offlineListeners.delete(cb);
                return;
        }
    }
    /**
     * Disconnects from the API
     */
    disconnect() {
        this.connected = false;
        this.disconnecting = true;
        if (this.connection) {
            this.connection.close();
        }
    }
    /**
     * Returns a unique signifier for this connection
     */
    getConnectionHash() {
        return OffWorldLiveStream.createHash(this.apiRoot, this.liveStreamSlug, this.clientAPIKey, this.debugMode, this.reactionThrottleTime);
    }
    static createHash(apiRoot, liveStreamSlug, clientAPIKey, debugMode, reactionThrottleTime) {
        return `${apiRoot}_${liveStreamSlug}_${clientAPIKey}_${debugMode}_${reactionThrottleTime}`;
    }
    /**
     * Called by setInterval
     * checks the reaction queue every x seconds
     * to flush the output to the server
     */
    checkQueue() {
        this.fillingQueue = false;
        const reactions = {};
        // consolidate all reactions in this time period
        // into single payload
        const queueCopy = [...this.reactionQueue];
        // clear the reaction queue
        this.reactionQueue = [];
        queueCopy.forEach((r) => {
            if (!reactions[r]) {
                reactions[r] = 0;
            }
            reactions[r]++;
        });
        // send payload for each reaction over wire to server
        Object.entries(reactions).map(([k, v]) => {
            this.debugLog('sending batch', k, 'count', v);
            this.send({
                count: v,
                reaction: k,
                type: ReactionClickType,
            });
        });
    }
    send(r) {
        if (!this.connection) {
            throw new Error('Not connected yet');
        }
        this.debugLog(`Sending reaction ${r.reaction}`);
        this.connection.send(JSON.stringify(r));
        return true;
    }
    onMessage(evt) {
        const decoded = JSON.parse(evt.data);
        switch (decoded.type) {
            case StateChangedType:
                this.handleStateChange(decoded.newState);
                return;
            case PercentCompleteType:
                this.handlePercentCompleteChange(decoded.reactionName, decoded.percentComplete);
                return;
            case ViewerChangedType:
                this.handleViewerChange(decoded.viewers);
                return;
            case CoolDownStartType:
                this.handleCoolDown(decoded.reactionName, true);
                this.handlePercentCompleteChange(decoded.reactionName, 0);
                return;
            case CoolDownEndType:
                this.handleCoolDown(decoded.reactionName, false);
                return;
            case InitialStateType: {
                this.handleInitialState(decoded);
                return;
            }
        }
        this.debugError('Unkown message received', decoded);
    }
    handleStateChange(newState) {
        if (newState === this.liveState.state) {
            return;
        }
        this.liveState = Object.assign(Object.assign({}, this.liveState), { state: newState });
        this.stateChangeListeners.forEach((l) => l(newState));
    }
    handlePercentCompleteChange(reactionName, percentComplete) {
        const currentReaction = this.liveState.reactions[reactionName];
        if (currentReaction &&
            currentReaction.percentComplete === percentComplete) {
            return;
        }
        this.liveState = Object.assign(Object.assign({}, this.liveState), { reactions: Object.assign(Object.assign({}, this.liveState.reactions), { [reactionName]: {
                    coolingDown: (currentReaction === null || currentReaction === void 0 ? void 0 : currentReaction.coolingDown) || false,
                    percentComplete,
                } }) });
        this.percentChangeListeners.forEach((p) => p(reactionName, percentComplete));
    }
    handleViewerChange(viewers) {
        if (this.liveState.viewers === viewers) {
            return;
        }
        this.liveState = Object.assign(Object.assign({}, this.liveState), { viewers });
        this.viewerChangeListeners.forEach((v) => v(viewers));
    }
    handleCoolDown(reactionName, coolingDown) {
        var _a, _b;
        if (((_a = this.liveState.reactions[reactionName]) === null || _a === void 0 ? void 0 : _a.coolingDown) === coolingDown) {
            return;
        }
        this.liveState = Object.assign(Object.assign({}, this.liveState), { reactions: Object.assign(Object.assign({}, this.liveState.reactions), { [reactionName]: {
                    coolingDown,
                    percentComplete: ((_b = this.liveState.reactions[reactionName]) === null || _b === void 0 ? void 0 : _b.percentComplete) || 0,
                } }) });
        this.coolDownChangeListeners.forEach((v) => v(reactionName, coolingDown));
    }
    handleInitialState(state) {
        let changed = false;
        const updates = {
            reactions: {},
        };
        if (state.viewers !== this.liveState.viewers) {
            changed = true;
            updates.viewers = state.viewers;
            this.viewerChangeListeners.forEach((v) => v(state.viewers));
        }
        if (state.liveStreamState !== this.liveState.state) {
            changed = true;
            updates.state = state.liveStreamState;
            this.stateChangeListeners.forEach((l) => l(state.liveStreamState));
        }
        Object.entries(state.reactionCoolingState).map(([key, val]) => {
            const existing = this.liveState.reactions[key];
            if (existing && existing.coolingDown === val) {
                return;
            }
            if (existing === undefined) {
                changed = true;
                updates.reactions = Object.assign(Object.assign({}, updates.reactions), { [key]: {
                        coolingDown: val,
                        percentComplete: 0,
                    } });
            }
            changed = true;
            updates.reactions = Object.assign(Object.assign({}, updates.reactions), { [key]: Object.assign(Object.assign({}, existing), { coolingDown: val }) });
            if (existing || val === true) {
                this.coolDownChangeListeners.forEach((v) => v(key, val));
            }
        });
        Object.entries(state.reactionPercentages).map(([key, val]) => {
            const existing = this.liveState.reactions[key];
            if (existing && existing.percentComplete === val) {
                return;
            }
            if (existing === undefined) {
                changed = true;
                updates.reactions = Object.assign(Object.assign({}, updates.reactions), { [key]: {
                        coolingDown: false,
                        percentComplete: val,
                    } });
            }
            changed = true;
            updates.reactions = Object.assign(Object.assign({}, updates.reactions), { [key]: Object.assign(Object.assign({}, existing), { percentComplete: val }) });
            if (existing || val > 0) {
                this.percentChangeListeners.forEach((p) => p(key, val));
            }
        });
        if (!changed) {
            return;
        }
        this.liveState = Object.assign(Object.assign(Object.assign({}, this.liveState), updates), { reactions: Object.assign(Object.assign({}, this.liveState.reactions), updates.reactions) });
    }
    dispatchConnectionChange(connected) {
        if (connected) {
            Array.from(this.onlineListeners.values()).map((cb) => cb());
            return;
        }
        Array.from(this.offlineListeners.values()).map((cb) => cb());
    }
    bindOnVisibility() {
        if (!hiddenKey || !visibilityChangeKey) {
            return;
        }
        document.addEventListener(visibilityChangeKey, this.handleVisibilityChange.bind(this));
    }
    bindIdleWatcher() {
        if (this.boundIdleWatcher) {
            return;
        }
        idleWatcher(this.onActive.bind(this), this.onIdle.bind(this), 30 * 1000);
        this.boundIdleWatcher = true;
    }
    onActive() {
        this.debugLog('on active');
        if (!this.idle) {
            if (!this.connected) {
                this.connect();
            }
            return;
        }
        this.idle = false;
        if (this.connected) {
            return;
        }
        this.connect();
    }
    onIdle() {
        this.debugLog('on idle');
        if (this.idle) {
            return;
        }
        this.idle = true;
        if (!this.connected) {
            return;
        }
        this.disconnect();
    }
    handleVisibilityChange() {
        if (!hiddenKey) {
            return;
        }
        if (document[hiddenKey]) {
            this.debugLog('handle invisible');
            this.onIdle();
        }
        else {
            this.debugLog('handle visible');
            this.onActive();
        }
    }
    handleConnectionError(e) {
        this.debugError('Connection Error', e, 'attempting retry');
        this.backoffRetry();
    }
    backoffRetry() {
        if (this.currentRetries <= this.connectionRetries) {
            this.debugLog('trying again after backoff delay');
            window.setTimeout(() => {
                this.currentRetries++;
                this.connect();
            }, Math.pow(1.5, this.currentRetries) * 200);
            return;
        }
        this.debugLog('Too many failures, aborting websockets connection');
    }
    debugLog(...args) {
        if (!this.debugMode) {
            return;
        }
        console.debug('[OffWorldLiveStream]', ...args);
    }
    debugError(...args) {
        if (!this.logErrors) {
            return;
        }
        console.error(...args);
    }
    getWSConnection(url) {
        return new WebSocket(url);
    }
}
OffWorldLiveStream.LiveStreamWaitingForStart = LiveStreamWaitingForStart;
OffWorldLiveStream.LiveStreamInProgress = LiveStreamInProgress;
OffWorldLiveStream.PerfomanceEnded = LiveStreamEnded;
export default OffWorldLiveStream;
