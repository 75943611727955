import React, { useEffect, useState, useRef } from 'react'
import useProgramme from '../scenes/useProgramme'


import ReactAudioPlayer from 'react-audio-player';

import Clap from '../clap/Clap';
import Close from "../ui/CloseButton";
import PrismicSection from '../prismic/PrismicSection'

import classes from './video-window.scss'

function AudioWindow(props) {

     const playingNow = useProgramme('radio');

     const containerWidth = props.showChat ? ' section-width1' : ' section-width2';
     const marginLeft = props.showChat ? ' section-margin-left1' : ' section-margin-left2';
     const marginRight = props.showChat ? ' section-margin-right1' : ' section-margin-right2';

     return (
          <div className='section-window'>
               <div className={'section-container ' + marginLeft + marginRight + containerWidth}>
                    <div className='section-close-container'>
                         <Close
                              handleClick={props.handleClose}
                              classes={"button-close-section"}
                         />
                    </div>
                    <div className='section-headline-container'>
                         Radio
                    </div>

                    <div className='top-line'></div>
                    <div className='section-audioplayer-container'>
                         <ReactAudioPlayer
                            src={props.audio.audio1.url}
                            controls
                            muted={props.muted}
                            className={'section-audioplayer'}
                        />
                    </div>
     
                    <div className='section-text-container'>
                         {playingNow !== undefined && playingNow !== '' ?
                              <div >
                                   <div className='section-text-artist'>
                                        {playingNow.name}
                                   </div>
                                   <div className='section-text-time'>
                                        {playingNow.from}-{playingNow.to}
                                   </div>
                                   <div className='section-text-description'>
                                        <PrismicSection uid={playingNow.note} showClose={false} showTitle={false} />
                                   </div>
                              </div>
                              :
                              ""
                         }
                    </div>
               </div>
               <Clap room={props.section} />
          </div>

     )
}

export default AudioWindow