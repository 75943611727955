import React from 'react';
import { useReactionPercentage } from './useReactionPercentage';
export const useTriggerState = (reactionName, onDurationMs = 1000) => {
    const [triggering, setTriggering] = React.useState(false);
    const lastPercent = React.useRef(0);
    const timer = React.useRef(0);
    const percent = useReactionPercentage(reactionName);
    React.useEffect(() => {
        if ((lastPercent.current && lastPercent.current > percent) ||
            percent === 100) {
            // set 'triggering' if
            setTriggering(true);
            window.clearTimeout(timer.current);
            timer.current = window.setTimeout(() => {
                if (lastPercent.current !== undefined) {
                    setTriggering(false);
                }
            }, onDurationMs);
        }
        () => {
            window.clearTimeout(timer.current);
        };
    }, [setTriggering, percent, onDurationMs]);
    return triggering;
};
