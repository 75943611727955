import React, { useState, useEffect } from 'react'

import Close from '../ui/CloseButton'

import classes from './welcome.scss'
import classes2 from './overview.scss'
import classes3 from '../ui/buttons.scss'

export default function Community(props) {

     return (
          <div className="popup-container">
               <Close handleClick={props.handleClick} classes={"button-close button-right"} />

               <div className='popup-scroll'>
                    <div className="popup-headline">
                         JOIN OUR COMMUNITY
                    </div>
                    <div className="popup-text">
                         <span className='popup-bold'>As a way to connect with our visitors and foster an active festival community, for the Rewire 2021 online edition we have created our own Discord server, where you can chat with your fellow visitors and artists before and during the festival. Find out more about how to get started below.</span>
                         <br></br><br></br>
                         A digital festival is not the same as a physical one. Nonetheless, we would like to make you feel as much part of a real festival vibe as possible. To make the most out of your festival experience, we have created a Discord channel, which allows you to chat in our virtual festival hub before, during, and after performances. To be able to use the chat, you need to sign up for Discord and join the Rewire channel, which is simply done by following the link below.
                         <br></br><br></br>
                         Join our Discord server here: <a href='https://discord.gg/g2rXqzdReU' target='_blank'>https://discord.gg/g2rXqzdReU</a>
                         <br></br><br></br>
                         Discord is a platform that contains both text and voice/video channels, as well as individual messaging; it was designed for creating communities. Creating a Discord account will enable you to chat with artists after their performances and meet your fellow visitors in any of the Discord chat rooms. Get started and head over to the Rewire Discord channel.
                         <br></br><br></br>
                         Have any questions about how to use Discord? Take a look at their helpful guide here: <a href ='https://support.discord.com/hc/en-us/categories/115000168371-F-A-Q-Troubleshooting'  target='_blank'>Discord FAQ</a>
                         <br></br><br></br>
                         The Rewire Discord server will be embedded onto the festival hub and we will also be using Discord for future projects and communication.
               </div>

               </div>
          </div>
     )
}

