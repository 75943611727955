import React from 'react';
import { useOffWorldLiveStream } from '../components/OffWorldLiveStreamProvider';
export const useStreamState = () => {
    const stream = useOffWorldLiveStream();
    const [state, setState] = React.useState(stream.state.state);
    React.useEffect(() => {
        stream.onStateChange(setState);
        return () => {
            stream.off('stateChange', setState);
        };
    }, [setState, stream]);
    return state;
};
