import React , { Suspense } from 'react'

import classes from "./buttons.scss";

function EnterButton (props) {

  const handleEnter = (e) => {
    e.preventDefault();
    props.handleClick();

  }

      return (
        <button className="button-square button-white button-enter" onClick={(e) => handleEnter(e)}>
            Enter ReWire 2021
        </button>
      )
  }
  
  export default EnterButton