import React from 'react';
import { useOffWorldLiveStream } from '../components/OffWorldLiveStreamProvider';
export const useReactionPercentage = (reactionName) => {
    var _a;
    const stream = useOffWorldLiveStream();
    const initPercent = ((_a = stream.state.reactions[reactionName]) === null || _a === void 0 ? void 0 : _a.percentComplete) || 0;
    const [percentComplete, setPercentComplete] = React.useState(initPercent);
    React.useEffect(() => {
        const handlePercentCompleteChange = (name, percent) => {
            if (name !== reactionName) {
                return;
            }
            setPercentComplete(percent);
        };
        stream.onPercentCompleteChange(handlePercentCompleteChange);
        return () => {
            stream.off('percentCompleteChange', handlePercentCompleteChange);
        };
    }, [stream, setPercentComplete, reactionName]);
    return percentComplete;
};
