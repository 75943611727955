import React from 'react';
import { OffWorldLiveStream } from '../../OffWorldLiveStream';
const Context = React.createContext(new OffWorldLiveStream({
    disabled: true,
}));
const globalIndex = new Map();
const globalCounter = new Map();
// to prevent rapid cancels / reloads, we persist the
// connection instance a few milliseconds before deleting
// so we can reuse it rather than cancelling and reestabilishing the connection
const globalDestroyTimers = new Map();
export const useOffWorldLiveStream = () => {
    const liveStream = React.useContext(Context);
    if (!liveStream) {
        console.warn('Off World Live stream hooks must be used within the <OffWorldLiveStreamProvider/>');
    }
    return liveStream;
};
export const OffWorldLiveStreamProvider = ({ liveStreamSlug, clientAPIKey, debugMode = false, apiRoot = 'https://api.offworld.live', reactionThrottleTime = 1000, children, }) => {
    const [localInstance, setLocalInstance] = React.useState(() => getInstance({
        liveStreamSlug,
        apiRoot,
        clientAPIKey,
        debugMode,
        reactionThrottleTime,
    }));
    React.useEffect(() => {
        const instance = getInstance({
            liveStreamSlug,
            apiRoot,
            clientAPIKey,
            debugMode,
            reactionThrottleTime,
        });
        setLocalInstance(instance);
        return () => {
            killInstance(instance);
        };
    }, [
        setLocalInstance,
        liveStreamSlug,
        clientAPIKey,
        debugMode,
        apiRoot,
        reactionThrottleTime,
    ]);
    console.debug('Provider', !!localInstance);
    return React.createElement(Context.Provider, { value: localInstance }, children);
};
const killInstance = (instance) => {
    const hash = instance.getConnectionHash();
    const count = globalCounter.get(hash) || 0;
    if (count === 0) {
        throw Error('Counter innacurate. Should be at least one instance here');
    }
    if (count > 1) {
        globalCounter.set(hash, count - 1);
        return;
    }
    // begin deletion
    globalCounter.set(hash, 0);
    globalDestroyTimers.set(hash, window.setTimeout(() => {
        instance.disconnect();
        globalIndex.delete(hash);
    }, 1000));
};
// Fetches the instance or creates it
const getInstance = ({ liveStreamSlug, clientAPIKey, apiRoot, debugMode, reactionThrottleTime, }) => {
    const hash = OffWorldLiveStream.createHash(apiRoot, liveStreamSlug, clientAPIKey, debugMode, reactionThrottleTime);
    // check if file exists
    const count = globalCounter.get(hash) || 0;
    if (count > 0) {
        const item = globalIndex.get(hash);
        if (!item) {
            throw new Error('counter and index out of sync');
        }
        globalCounter.set(hash, count + 1);
        return item;
    }
    // if a copy of this class is scheduled for removal
    // stop removal and reuse it
    const timerHandle = globalDestroyTimers.get(hash);
    if (timerHandle) {
        window.clearTimeout(timerHandle);
        globalDestroyTimers.delete(hash);
        const rescuedItem = globalIndex.get(hash);
        if (rescuedItem) {
            globalCounter.set(hash, 1);
            return rescuedItem;
        }
    }
    // otherwise instantiate this class
    const instance = new OffWorldLiveStream({
        apiRoot,
        liveStreamSlug,
        clientAPIKey,
        debugMode,
        reactionThrottleTime,
    });
    instance.connect();
    globalCounter.set(hash, 1);
    globalIndex.set(hash, instance);
    return instance;
};
