import React from 'react'
import Close from "../ui/CloseButton";

import classes from "./overview.scss";

export default function Overview(props) {

     return (
          <div className="overview-container">
               <Close handleClick={props.handleClick} classes={"button-close button-right"} />

               <div className='overview'>
               <div className="popup-headline">
                    Virtual Festival Hub - How To
                    
               </div>
               <div className='popup-text'>
               <br></br>
                    <div className='popup-bold'>What is the virtual festival hub?</div>
                    The virtual festival hub is an interactive website where the Rewire 2021 online edition is hosted. Here you can access the entire Rewire 2021 programme from 6-9 May.
                    <br></br><br></br>
                    <div className='popup-bold'>Where can I find the performance I want to see?</div>
                    Check the timetable in the top left corner (click artists names and more info will pop-up). Please note that these times are Central European Summer Time (CEST): to see the timetable in your own local timezone visit the timetable on our <a href='http://www.rewirefestival.nl/timetable' target='_blank'>general website</a>, which is automatically set to your local timezone.
                    <br></br><br></br>
                    <div className='popup-bold'>Do I have to buy a ticket?</div>
                    No, the online festival is freely accessible for everyone. However, as a non-profit organisation donations are greatly appreciated, helping us continue to present adventurous music from across the globe and invest in the creation of new works. You can make a donation <a href='http://live.rewirefestival.nl/donate' target='_blank'>here</a>.
                    <br></br><br></br>
                    <div className='popup-bold'>Live streams & radio</div>
                    The virtual festival hub enables you to browse between two video channels, where you will find the live streams, and a radio channel, where you can access the radio programme. You can also download our radio app on your smartphone at <a href='http://www.rewireradio.nl' target='_blank'>www.rewireradio.nl</a> for easy radio access on the go. Please note this only works for Android. For Apple, please play the radio directly in your browser, it will continue playing when your phone switches to stand-by mode.
                    <br></br>
                    — Android / Chrome: Start Chrome and open <a href='http://www.rewireradio.nl' target='_blank'>www.rewireradio.nl</a>. Tap the menu button (three dots at the top right) and tap "Install app". You can now find the app on the home screen of your smartphone.
                    <br></br>
                    — Android / Samsung internet: Start the browser and open <a href='http://www.rewireradio.nl' target='_blank'>www.rewireradio.nl</a>. Tap the menu button (3 stripes bottom right) and tap "Add page to". Then tap "Apps screen" and install the app. You can now find the app on the home screen of your smartphone.
                    <br></br><br></br>
                    <div className='popup-bold'>Video on demand</div>
                    In the video on demand section you can rewatch a selection of live premiered performances, films, and talks from the day(s) before.
                    <br></br><br></br>
                    <div className='popup-bold'>Portals</div>
                    Visit the portals to access several (interactive) projects that are hosted outside of the festival hub. These projects are open all day.
                    <br></br><br></br>
                    <div className='popup-bold'>Chat</div>
                    If you would like to join the chat during the festival, you can easily sign up for Discord <a href='https://discord.com/register' target='_blank'>here</a> and join the Rewire channel, or head straight to the <a href='https://discord.gg/Xv3cvhUGGT' target='_blank'>Rewire Discord server</a> if you already have an account. Discord is a platform that contains both text and voice/video channels, as well as individual messaging; it was designed for creating communities.
                    Creating a Discord account enables you to chat with your fellow visitors and artists before, during and after performances.

                    <br></br><br></br><br></br>
                    <div className='popup-bold'>How do I navigate the virtual festival hub?</div>
                    Navigating the different sections is simple, you can use your mouse to scroll left and right or click the arrows on either side of the screen to switch between channels. There is also a dropdown menu in the top left corner of the screen, next to the timetable, where you can easily toggle between channels.
                    <br></br><br></br>
                    Once you’ve selected a video channel, you click the play button to enter and come to the video player, which you can either make full screen for full immersion or leave it at its current size and interact with your fellow audience members via the chat.
                    <br></br><br></br>
                    <div className='popup-bold'>Tips for the best experience</div>
                    Optimize your festival experience by using headphones or stereo speakers and a large display. You can hook up your computer to your TV using a HDMI cable. Alternatively you can cast the video from your browser or your mobile phone to your TV using Chromecast (only works with Chrome browser) or Apple Airplay.
                    <br></br><br></br>
                    We highly recommend using the Chrome browser for navigating the virtual festival hub.
                    <br></br><br></br><br></br>

                    <div className='popup-bold'>I don’t want to sign up for Discord, can I still stay connected?</div>
                    Yes, you can contact us during the festival via Facebook, Twitter, Instagram and by emailing <a href='mailto:pr@rewirefestival.nl' target='_blank'>pr@rewirefestival.nl</a>. To stay up to date about future events, you can also sign up for our newsletter here.
                    <br></br><br></br>
                    <div className='popup-bold'>Help! Something is not working...</div>
                    Get in touch via the chat, the Discord #support channel or e-mail us at <a href='mailto:pr@rewirefestival.nl' target='_blank'>pr@rewirefestival.nl</a>.
                    <br></br><br></br>
                    <div className='popup-bold'>Looking for even more information?</div>
                    There is some more useful general information available in our Practical Guide <a href='https://www.rewirefestival.nl/news/a-practical-guide-to-rewire-2021-online-edition' target='_blank'>here</a>.

                    <br></br><br></br><br></br><br></br><br></br>


                    <div className='popup-bold'>Credits</div>
                    The virtual festival hub is made by The Rodina and designed by Anja Kaiser & Jim Kühnel.
                    <br></br><br></br>
                    Rewire 2021 - online edition is made possible by: The Hague, Fonds Podiumkunsten, Fonds 21, Stimuleringsfonds Creatieve Industrie, Dioraphte, Prins Bernhard Cultuurfonds, VSBfonds, Fonds 1818. Presented in collaboration with Carhartt WIP.

               </div>
               </div>

          </div>
     )
}