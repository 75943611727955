import React from 'react'
import Close from '../ui/CloseButton'

import classes from "./portals.scss"
import classes2 from '../ui/buttons'
import classes3 from "../window/video-window.scss";


export default function MSHR(props) {

     const containerWidth = props.showChat ? ' section-width1' : ' section-width2';
     const marginLeft = props.showChat ? ' section-margin-left1' : ' section-margin-left2';
     const marginRight = props.showChat ? ' section-margin-right1' : ' section-margin-right2';

     return (
          <div className='section-window'>
               <div className={'section-container-portals ' + marginLeft + marginRight + containerWidth}>

                    <div className='portals-close-container'>
                         <Close
                              handleClick={props.handleClose}
                              classes={"button-close-section"}
                         />
                    </div>
                    <div className='portals-leftcolumn'>
                         <div className='portals-title'>
                              MSHR
                         </div>

                         <div className='portals-description'>
                              'Instance Terrain Crawler'
                         </div>

                         <div className='portals-description2'>
                              This project is hosted on an{' '}
                              <a href='http://mshr.rewirefestival.nl/' target='_blank' className='portals-link'>external website.</a>
                         </div>

                         <a href='http://mshr.rewirefestival.nl/' target='_blank'>
                              <img src='./images/portals/mshr.jpg' className='portals-image'></img>
                         </a>



                    </div>

                    <div className='portals-rightcolumn'>
                         MSHR is an art collective formed by Birch Cooper and Brenna Murphy. The duo builds sculptural electronic systems that they use to create performances and installations. They have conceived a special work for Rewire 2021 entitled 'Instance Terrain Crawler': an interactive audiovisual composition that takes form as a navigable virtual space. Building on their decade-long series of interactive installations, this project dives into the virtual dimension. Visitors will play the composition by traversing the rooms and hallways of a cybernetic architecture. In this game-like virtual domain, time and space are linked as the composition unfolds through navigation.
                    </div>

               </div>
          </div>

     )
}

