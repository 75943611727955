import React, { Suspense } from "react";

import {
    Router as Router,
    Route,
    Redirect,
} from "react-router-dom";


function PrivateRoute(props) {
    return (
        <Route
            render={({ location }) =>
                props.user && props.login ? (
                    props.children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/",
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
}

export default PrivateRoute