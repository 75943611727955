import React, { useEffect, useState } from "react";
import ReactPlayer from 'react-player';
import { useInView } from 'react-intersection-observer';

import classes2 from "../scene/slider.scss";
import useProgramme from './useProgramme'



function Livestream(props) {


    const playingNow = useProgramme('livestream');

    const { ref, inView, entry } = useInView();
    


    const play = (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 77.87 77.87"
        >
            <g data-name="Vrstva 2">
                <circle
                    cx={38.94}
                    cy={38.94}
                    r={37.94}
                    fill="none"
                    stroke="#fff"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                />
                <path
                    d="M56.14 36.56L31.89 16.85A3 3 0 0027 19.18V58.6a3 3 0 004.89 2.33l24.25-19.71a3 3 0 000-4.66z"
                    fill="#fff"
                />
            </g>
        </svg>
    )

    return (
        <div className="slider-section-content">

            <div className="slider-livestream-container" onClick={() => props.handleOnClick(2)}>
                <div className='slider-play'>
                    {play}
                </div>
                <div className='slider-player-container' ref={ref}>
                    <ReactPlayer
                        className='slider-player'
                        url='https://player.vimeo.com/external/535799168.m3u8?s=bc94c054e98fea7be946185958a500e935c29ca1'
                        muted={true}
                        playing={inView}
                        loop={true}
                        width='100%'
                        height='auto'

                        playsinline={true}
                        config={{
                            file: {
                                attributes: { playsInline: true },
                                hlsOptions: {
                                    // debug: true,
                                },
                                hlsVersion: '1.0.2'
                            }
                        }}
                    />
                </div>
                <div className='slider-text-container'>
                    <div className='slider-headline'>
                        Video Channel II
                    </div>
                    <div className='slider-description'>
                    {playingNow !== undefined && playingNow !== ''  ?
                            <div>
                                {playingNow.name}
                            </div>
                            :
                            ''
                        }

                    </div>
                </div>

            </div>

        </div>
    );
}

export default Livestream;

