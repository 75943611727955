import './style/style.scss'

import * as React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App'


// 
// Attach React app
// 
ReactDOM.render (
    <App />,
    document.getElementById('root')
)


import { Notifier } from '@airbrake/browser';


if (process.env.NODE_ENV === 'development') {
  console.log('Running in development');
} else {
  const airbrake = new Notifier({
    projectId: 329941,
    projectKey: '6c477dffada2aa3a6a2b98b48b62783c',
    environment: 'production'
  });
}