import React from 'react'
import Close from '../ui/CloseButton'

import classes from "./portals.scss"
import classes2 from '../ui/buttons'
import classes3 from "../window/video-window.scss";


export default function LisaHall(props) {

     const containerWidth = props.showChat ? ' section-width1' : ' section-width2';
     const marginLeft = props.showChat ? ' section-margin-left1' : ' section-margin-left2';
     const marginRight = props.showChat ? ' section-margin-right1' : ' section-margin-right2';

     return (

          <div className='section-window'>
               <div className={'section-container-portals ' + marginLeft + marginRight + containerWidth}>

                    <div className='portals-close-container'>
                         <Close
                              handleClick={props.handleClose}
                              classes={"button-close-section"}
                         />
                    </div>
                    <div className='portals-leftcolumn'>
                         <div className='portals-title'>
                              Acts of Air: reshaping the urban sonic
                         </div>

                         <div className='portals-description'>

                         </div>
                         <div className='portals-description2'>
                              This project is hosted on an{' '}
                              <a href='http://acts-of-air.crisap.org/?utm_source=rewire' target='_blank' className='portals-link'>external website.</a>
                         </div>

                         <a href='http://acts-of-air.crisap.org/?utm_source=rewire' target='_blank'>
                              <img src='./images/portals/acts-of-air2.jpg' className='portals-image'></img>
                         </a>




                    </div>

                    <div className='portals-rightcolumn'>
                         Curated by Lisa Hall for CRiSAP, Creative Research into Sound Arts Practice research centre at University of the Arts London, the exhibition brings together 14 artists based around the world: Agnes Paz, Anna Lann, Anna Raimondo, Anne Leilehua Lanzilotti, Catherine Clover, Cedric Maridet, Colin Priest, Jacek Smolicki, Julieanna Preston, Kate Brown, Raheel Khan, Vagné, Vitório O. Azevedo and Yara Mekawei.
                         <br></br>
                         <br></br>
                         The exhibition invites you to reshape your urban sonic present by enacting the 14 sound works directly in urban spaces. Each artwork invites a sounding out, a performance, a site specific listening or engagement in the streets, under bridges, by water fountains and extractor fans. They offer a means to explore and interrogate our cities of sound, and can be enacted and activated anywhere around the globe.
                    </div>
               </div>
          </div>

     )
}

