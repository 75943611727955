import React, { useState, useEffect } from "react";

import classes from "../scene/slider.scss";
import classes3 from '../ui/buttons.scss'


function VideoOnDemand(props) {

    const [thu6, setThu6] = useState(props.videoOnDemand.thu6);
    const [fri7, setFri7] = useState(props.videoOnDemand.fri7);
    const [sat8, setSat8] = useState(props.videoOnDemand.sat8);
    const [sun9, setSun9] = useState(props.videoOnDemand.sun9);

    useEffect(() => {
        setThu6(props.videoOnDemand.thu6);
        setFri7(props.videoOnDemand.fri7);
        setSat8(props.videoOnDemand.sat8);
        setSun9(props.videoOnDemand.sun9);

    },
        [props.videoOnDemand.thu6.open,
        props.videoOnDemand.fri7.open,
        props.videoOnDemand.sat8.open,
        props.videoOnDemand.sun9.open,
        ])


    return (
        <div className="slider-section-content">
            {Date("slider-videoondemand1", thu6, () => props.handleOnClick(6), 'Thursday 6', "./images/videoondemand/videoondemand1.png")}
            {Date("slider-videoondemand2", fri7, () => props.handleOnClick(7), 'Friday 7', "./images/videoondemand/videoondemand2.png")}
            {Date("slider-videoondemand3", sat8, () => props.handleOnClick(8), 'Saturday 8', "./images/videoondemand/videoondemand3.png")}
            {Date("slider-videoondemand4", sun9, () => props.handleOnClick(9), 'Sunday 9', "./images/videoondemand/videoondemand4.png")}
        </div>
    );
}

export default VideoOnDemand;

function Date(classes, day, link, text, bg) {
    return (
        <div className={classes + " slider-videoondemand" + (day.open ? '' : ' slider-videoondemand-inactive')} onClick={day.open ? link : null}>

            {day.open ?
                <div className='slider-videoondemand-text'>
                    VIDEO ON DEMAND<br></br>
                    {text} May
                </div>
                :
                <div className='slider-videoondemand-text slider-videoondemand-text-2'>
                    VIDEO ON DEMAND<br></br>
                    {text} May
                </div>
            }

            <img className='slider-videoondemand-image' src={bg}></img>
        </div>
    )
}