import React from "react";
import { Link } from "react-router-dom";
import EnterButton from "../ui/EnterButton";
import CharacterForm from "../ui/CharacterForm.jsx";

import classes from "./splashscreen.scss";
import classes2 from "./auth.scss";
// import About from "./About";
// import AboutButton from "../ui/AboutButton";
import ReactPlayer from 'react-player'
import Logo from '../ui/Logo';
import DonateButton from "../ui/DonateButton";

class LandingPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            characterValue: this.props.characterValue,
            showAbout: false,
            shortName: false,
        }
    };

    setShowAbout = (bool) => {
        this.setState({
            showAbout: bool
        })
    }

    handleCharacterChange = (value) => {

        const numberOfPictures = 20;

        let newValue = this.state.characterValue;
        newValue = (newValue + value + numberOfPictures) % numberOfPictures;
        this.setState({
            characterValue: newValue,
        })
    }

    handleEnterApp = () => {
        if (this.props.name !== '' || !this.props.firstTimeHere) {
            this.props.handleCharacterChange(this.state.characterValue);
            this.props.handleEnterApp();
        } else {
            this.setState({
                shortName: true
            })
        }
    }

    handleEnterPlayer = () => {
        this.props.handleEnterPlayer();
    }

    componentDidMount() { }

    componentWillUnmount() { }


    render() {
        return (
            <div className="splashScreen-landing">
                <Logo classes={'logo logo-left'} />


                <div className="auth">
                    {this.props.loading ?
                        <div className='landing-loading-wrapper'>
                            <div className='landing-loader'> </div>
                            <div className='landing-loading-text'>Loading</div>
                        </div>
                        :
                        <div>
                            {this.props.firstTimeHere === null ?
                                <div className='landing-loading-wrapper'>
                                    <div className='landing-loader'> </div>
                                    <div className='landing-loading-text'>Loading</div>
                                </div>
                                :
                                ""
                            }

                            {false === false ?
                                <div className={"auth-title auth-title-short"}>
                                    <div className='auth-welcome'>
                                        {/* Welcome back <br />
                                        {this.props.user !== null ? this.props.user.displayName : ""} */}
                                        Rewire 2021 - online edition is over, thanks for joining us!<br/> 
                                        You can still donate <a href="https://www.rewirefestival.nl/donate">here</a><br/> 
                                        Stay informed about future activities and subscribe to our newsletter <a href="https://www.rewirefestival.nl/">here</a><br/> 
                                    </div>
                                    {/*
                                    <EnterButton handleClick={this.handleEnterApp} />
                                    <div className='landing-recommended'>
                                        Chrome and Firefox (desktop) browsers and headphones or stereo audio are recommended for the best experience
                                    </div> */}
                                </div>
                                :
                                ""
                            }
                            {this.props.firstTimeHere === true ?
                                <a className={"auth-title auth-title-tall"}>
                                    <div>
                                        <CharacterForm
                                            handleCharacterChange={this.handleCharacterChange}
                                            handleEnter={this.handleEnterApp}
                                            value={this.state.characterValue}
                                            handleNameChange={this.props.handleNameChange}
                                            name={this.props.name}
                                            logged={false}
                                        />
                                        <div className='landing-shortname'>
                                            {this.state.shortName && this.props.firstTimeHere ?
                                                <div>
                                                    You need to write a name!
                                                </div>
                                                :
                                                ""
                                            }
                                        </div>

                                    </div>
                                    <EnterButton handleClick={this.handleEnterApp} />
                                    <div className='landing-recommended'>
                                        Chrome and Firefox (desktop) browsers and headphones or stereo audio are recommended for the best experience
                                    </div>
                                </a>
                                :
                                ""
                            }


                        </div>



                    }

                </div>

                <div className="splashScreen-image">
                    {/* <img src="./images/landigpage2.png" /> */}
                    <Player />

                </div>
                <DonateButton history={this.props.history}/>
            </div>
        );
    }
}

export default LandingPage;

function Player(props) {
    return (
        <ReactPlayer
            url='https://player.vimeo.com/external/535894568.m3u8?s=a4f7d193888d5aa82e3efdc09ed187257fa625ff'
            muted={true}
            controls={false}
            playing={true}
            width="100%"
            height="100%"
            className="splashScreen-video"
            loop
            playsinline={true}
            config={{
                file: {
                    attributes: { playsInline: true },
                    hlsOptions: {
                        // debug: true,
                    },
                    hlsVersion: '1.0.2'
                }
            }}

        />
    )

}
