import React, { Suspense } from "react";

import {
    Router as Router,
    Switch,
    Route,
    Link,
    Redirect,
} from "react-router-dom";

import { createBrowserHistory } from "history";

// 
// Ui elements and pages
// 

import LandingPage from "./pages/LandingPage";

//
// Static pages
// 

import Privacy from "./pages/Privacy";
import Scene from "./scene/Scene";
import Player from "./pages/Player";
import Donate from "./Donate";


// 
// Utilities 
// 

import PrivateRoute from './utils/PrivateRoute';
import firebase from './utils/firebase'

const history = createBrowserHistory();

// 
// Classes 
// 


// 
// Main app component
// 

class App extends React.Component {
    constructor(props) {

        super(props);

        this.state = {
            user: null,
            login: false,

            loading: null,
            firstTimeHere: null,

            name: "",
            characterValue: Math.floor(Math.random() * 20),

            muted: false,
            flash: "",

            activePortals: {
                portal1: { description: '', open: false },
                portal2: { description: '', open: false },
                portal3: { description: '', open: false },
                portal4: { description: '', open: false },
                portal5: { description: '', open: false },
                portal6: { description: '', open: false },
                portal7: { description: '', open: false },
            },
            videoOnDemand: {
                thu6: { open: false },
                fri7: { open: false },
                sat8: { open: false },
                sun9: { open: false },
            },
            audio: {
                audio1: { url: '' },
                audio2: { url: '' },
            },
            videos: {
                video1: { url: '' },
                video2: { url: '' },
                video3: { url: '' },
            },
            cliffordSage: {
                open: false,
            },

            _isMounted: false,
        };

        firebase.auth().onAuthStateChanged((user) => {

            if (user === null) {
                if (this.state.firstTimeHere === null) {
                    this.setState({
                        // show form *
                        // with name *
                        // 2 options avatar *
                        // and enter buton *
                        firstTimeHere: true,
                    })
                }

            } else {
                if (this.state.firstTimeHere === null) {
                    this.setState({
                        firstTimeHere: false,
                    })
                    // show welcome back
                    this.setState({
                        user: user,
                    });
                }
            }
        });

        let flashRef = firebase.database().ref("flash/");
        flashRef.on("value", (snapshot) => {
            const message = snapshot.val().message.trim()
            this.setState({
                flash: message
            })
        });


        let portalsRef = firebase.database().ref("portals/");
        portalsRef.on("value", (snapshot) => {
            this.setState({
                activePortals: snapshot.val()
            })
        });

        let videoOnDemandRef = firebase.database().ref("videoOnDemand/");
        videoOnDemandRef.on("value", (snapshot) => {
            this.setState({
                videoOnDemand: snapshot.val()
            })
        });

        let audioRef = firebase.database().ref("audio/");
        audioRef.on("value", (snapshot) => {
            this.setState({
                audio: snapshot.val()
            })
        });

        let videosRef = firebase.database().ref("videos/");
        videosRef.on("value", (snapshot) => {
            this.setState({
                videos: snapshot.val()
            })
        });

        let cliffordRef = firebase.database().ref("cliffordSage/");
        cliffordRef.on("value", (snapshot) => {
            this.setState({
                cliffordSage: snapshot.val()
            })
        });

    }

    /**
     * Enter App
     */
    handleEnterApp = () => {
        this.setState({
            loading: true,
        })

        if (this.state.user) {
            this.setState({
                login: true,
                loading: false,
            });
            history.push("/scene");
        } else {

            firebase
                .auth()
                .signInAnonymously()
                .then((newUser) => {
                    this.setState({
                        login: true,
                        user: newUser.user,
                    });

                    let name = this.state.name;

                    newUser.user.updateProfile({
                        displayName: name,
                        photoURL: "https://ams3.digitaloceanspaces.com/rewire/middle/middle_" + this.state.characterValue + ".png",
                    }).then(() => {
                        return this.handleUserUpdate();
                    }).then(() => {
                        this.setState({
                            loading: false,
                        })
                        history.push("/scene");
                    })
                }).catch((error) => {
                    var errorCode = error.code;
                    var errorMessage = error.message;
                });
        }
    };

    /**
     * Enter Player
     */
    enterPlayer = () => {
        history.push("/player");
    }



    handleUserUpdate = () => {
        this.state.user.reload().then((updatedUser) => {
            this.setState({
                user: firebase.auth().currentUser,
            });
        });
    };

    handleMute = () => {
        this.setState({
            muted: (!this.state.muted)
        })
    }

    /**
     * Set new value for user character/avatar.
     *
     */
    setCharacter = (int) => {
        this.setState({
            characterValue: int,
        })
    }

    setName = (name) => {
        this.setState({
            name: name
        })
    }

    /**
     * Exit to Landing Page
     */
    handleExit = () => {
        firebase.auth().signOut().then(() => {
            this.setState({
                user: null,
                name: "",
                firstTimeHere: true,
            })
        })
    }

    componentDidMount() {
        this.setState({
            _isMounted: true
        })
    }

    componentWillUnmount() {
        this.setState({
            _isMounted: false
        })
    }

    handleLogOut() {
        // firebase.auth.ignOut().then(()=>{
        //     this.setState({ user: null }) 
        // })
    }

    render() {
        return (
            <Router history={history}>
                <div className="main-container">
                    <Switch location={location}>

                        <PrivateRoute
                            path="/scene"
                            user={this.state.user}
                            login={this.state.login}
                        >
                            <Scene
                                user={this.state.user}
                                history={history}
                                firstTimeHere={this.state.firstTimeHere}
                                muted={this.state.muted}
                                characterValue={this.state.characterValue}
                                flash={this.state.flash}
                                flashPurge={()=>{this.setState({flash:''})}}
                                activePortals={this.state.activePortals}
                                videoOnDemand={this.state.videoOnDemand}
                                audio={this.state.audio}
                                videos={this.state.videos}
                                cliffordSage={this.state.cliffordSage}
                                handleMute={this.handleMute}
                                handleUserUpdate={this.handleUserUpdate}
                                handleLogOut={this.handleLogOut}

                                handleExit={this.handleExit}
                            />
                        </PrivateRoute>

                        <Route path="/player">
                            <Player
                                handleClick={this.handleExit}
                            />
                        </Route>

                        <Route path="/privacy">
                            <Privacy handleClick={this.handleExit} />
                        </Route>

                        <Route path="/donate">
                            <Donate history={history} />
                        </Route>


                        <Route path="/">
                            <LandingPage
                                user={this.state.user}
                                handleCharacterChange={this.setCharacter}
                                handleNameChange={this.setName}
                                handleEnterApp={this.handleEnterApp}
                                handleEnterPlayer={this.enterPlayer}

                                characterValue={this.state.characterValue}
                                name={this.state.name}
                                firstTimeHere={this.state.firstTimeHere}
                                loading={this.state.loading}
                                history={history}
                            />
                        </Route>
                    </Switch>
                </div>
            </Router>
        );
    }
}

export default App;
