import React from 'react'
import classes from "./flash.scss";
import classes2 from "./buttons.scss";
import CloseButton from "../ui/CloseButtonSmall"

function Flash(props) {
    // {props.flashPurge()

    return (
        <div className="flash-container" onClick={()=>props.flashPurge()}>
            <div className="flash-content">{props.message}</div>
            <CloseButton classes={"flash-close"} />
        </div>
    )
}


export default Flash