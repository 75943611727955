import React, { useState } from 'react'
import CharacterButton from "./CharacterButton";
import FileUploader from "react-firebase-file-uploader";


import classes from "./character.scss";
import classes2 from '../ui/buttons.scss'

function CharacterForm(props) {

    const [showUpload, setShowUpload] = useState(false);

    const uploadStart = (e) => {
        props.handleUploadStart(e);
    }

    const uploadSuccess = (e) => {
        props.handleUploadSuccess(e);
    }

    function changeValue(value) {
        props.handleCharacterChange(value);

    }

    function changeName(event) {
        if(event.target.value.length <= 20){
            props.handleNameChange(event.target.value);
        }
    }

    const nameSubmit = (e) => {
        e.preventDefault();
        props.handleEnter();
    }

    return (
        <div className="character">
            <div className={"character-headline"}>
                {props.logged ?
                    'Edit you avatar'
                    : "Select your avatar"
                }
            </div>

            <div className="character-form">
                {props.logged ?
                    <div className='character-image-container' onMouseEnter={() => setShowUpload(true)} onMouseLeave={() => setShowUpload(false)}>
                        <img src={props.path} className="character-image" />
                        <label >
                            {showUpload ?
                                <div className='button-upload'>
                                    Upload file
                            </div>
                                :
                                ""
                            }

                            <FileUploader
                                hidden
                                accept="image/*"
                                name="avatar"
                                randomizeFilename
                                storageRef={props.storageRef}
                                onUploadStart={uploadStart}
                                onUploadError={props.handleUploadError}
                                onUploadSuccess={uploadSuccess}
                            />
                        </label>


                    </div>
                    :
                    <div>
                        <CharacterButton value={props.value} handleClick={changeValue} />
                    </div>
                }

                <div className={"character-text"}>
                    Name
                </div>
                <form onSubmit={nameSubmit}>
                    <input type="text" name="name" placeholder={'Enter name'} value={props.name} onChange={changeName} className="character-input" />
                </form>

            </div>
        </div>
    )
}

export default CharacterForm