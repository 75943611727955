import React from "react";
import { OffWorldLiveStream } from './library/build/module'
import { OffWorldLiveStreamProvider } from './library/build/module'
import { ReactionButton } from './library/build/module'

import classes from '../ui/buttons.scss'

import Percents from './Percents'
import './clifford.scss'

const Clifford = (props) => (
    <OffWorldLiveStreamProvider
        liveStreamSlug="rewire"
        clientAPIKey="GUf9bcr9hEngJtk7VoNZZA5YD9LBwS"
    >
        <div className={props.classes}>

            <ReactionButton
                reactionName="findfish"
                className="button-square button-white border button-clifford"
                percentageComponent={(percentComplete) => (
                    <Percents percentComplete={percentComplete} />
                )}
            >
                <div className="your-custom-elements-inside-button">Find Fish</div>
            </ReactionButton>
            <ReactionButton
                reactionName="prodancersing"
                className="button-square button-white border button-clifford"
                percentageComponent={(percentComplete) => (
                    <Percents percentComplete={percentComplete} />
                )}
            >
                <div className="your-custom-elements-inside-button">Pro Dancer Sing</div>
            </ReactionButton>
            <ReactionButton
                reactionName="savefish"
                className="button-square button-white border button-clifford"
                percentageComponent={(percentComplete) => (
                    <Percents percentComplete={percentComplete} />
                )}
            >
                <div className="your-custom-elements-inside-button">Save Fish</div>
            </ReactionButton>

        </div>
    </OffWorldLiveStreamProvider>
);


export default Clifford