import React, { useState } from 'react';
import vhCheck from 'vh-check'

import classes from '../ui/buttons.scss'

export default function UserPosition(props) {

     function getSceneFromPosition() {
          let position = props.userPosition;
          if (position === undefined) {
               return ("Landing Zone");
          }
          // width is 240vh
          let widthOfScene;
          if (vhCheck().isNeeded){
               widthOfScene = 2.4 * vhCheck().vh;
          } else {
               widthOfScene = 2.4 * window.innerHeight;
          }
          const borderOfScene = position - window.innerWidth / 2;
          const sceneNumber = Math.floor(Math.abs(borderOfScene / widthOfScene));

          let sceneName;
          switch (sceneNumber) {

               case 1:
                    sceneName = "Video On Demand"
                    break;
               case 2:
                    sceneName = "Portals"
                    break;
               case 3:
                    sceneName = "Landing Zone"
                    break;
               case 4:
                    sceneName = "Video Channel I"
                    break;
               case 5:
                    sceneName = "Video Channel II"
                    break;
               case 6:
                    sceneName = "Radio"
                    break;
               default:

                    if (sceneNumber === 0){
                         sceneName = "Video On Demand";
                    } else if(sceneNumber === 7){
                         sceneName = "Radio";
                    } else {
                         sceneName = "";
                    }
          }
         
          return (sceneName);
     }

     function setPosition(i){
          let widthOfScene;
          if (vhCheck().isNeeded){
               widthOfScene = 2.4 * vhCheck().vh;
          } else {
               widthOfScene = 2.4 * window.innerHeight;
          }
          let position = (- i * widthOfScene) - (widthOfScene / 2) + (window.innerWidth / 2); // Should be (window.innerWidth / 2), content centered wrong?
          props.setScrollPosition(position);

     }

     function unpackedPosition(){
          const position = getSceneFromPosition();
          const classesHighlighted = "button-position-unpacked button-position-highlighted button-square";
          const classes = 'button-position-unpacked button-square';
          return (
               <div>


                    <div className={position == "Landing Zone" ? classesHighlighted : classes} onClick={() => setPosition(3)}>Landing Zone</div>

                    <div className={position == "Video Channel I" ? classesHighlighted : classes} onClick={() => setPosition(4)}>Video Channel I</div>
                    <div className={position == "Video Channel II" ? classesHighlighted : classes} onClick={() => setPosition(5)} >Video Channel II</div>
                    <div className={position == "Radio" ? classesHighlighted : classes} onClick={() => setPosition(6)}>Radio</div>

                    {/* <div className={position == "Empty Zone" ? classesHighlighted : classes} onClick={() => setPosition(0)} >Empty Zone</div> */}
                    <div className={position == "Video On Demand" ? classesHighlighted : classes} onClick={() => setPosition(1)}>Video On Demand</div>
                    <div className={position == "Portals" ? classesHighlighted : classes} onClick={() => setPosition(2)} style={{'borderBottom': '2px solid white'}}>Portals</div>
               </div>
          )
     }

     function handlePositionPacking (){
          if (props.positionPacked === true){
               props.handlePositionPacking(false);
          } else {
               props.handlePositionPacking(true);
          }
     }

     return (
          <div>{props.inWindow ?
               ""
               :
               <div>
               {props.positionPacked ?

                    <div className="button-square button-gray button-position-packed border" onMouseEnter={() => handlePositionPacking()} onClick={() => handlePositionPacking()}>
                         {getSceneFromPosition()}
                    </div>
                    :
                    <div className="button-position-unpacked-container"  onMouseLeave={() => handlePositionPacking()}>
                         {unpackedPosition()}
                    </div>
               }
               </div>
               }
          </div>

     )
}

