import React, { Suspense } from 'react'
import { Link } from "react-router-dom"

import Close from '../ui/CloseButton'

import classes from "./privacy.scss";

export default function Privacy(props) {

  return (
    <div className="privacy-container">
      <Link to="/" onClick={props.handleClick}>
        <Close classes={"button-close button-right"} />
      </Link>
      <div className="privacy">
        PRIVACY STATEMENT Rewirefestival.nl<br/><br/>

        <p>This statement explains how and why the Rewirefestival.nl website collects, uses and protects visitor data. For questions or comments, send an email to: vit@therodina.com. Rewirefestival.nl reserves the right to change this privacy statement.</p>
        <br/>
        <p>ACCESSIBLE WEBSITE</p>
        <p>The purpose of this website is to provide a user-friendly, informative and relevant environment that meets the needs of the user. The personal data that we process on our server is: data about your activities on our website, IP address, internet browser and device type. We analyse this data in order to secure and improve our website. We never provide personal data to third parties.</p>
        <p>The website contains links to other websites. Rewirefestival.nl has no influence on these websites and is not responsible for their content or policy.</p>
        <br/>
        <p>PERSONAL DATA</p>
        <p>Privacy relates to personal data that can be traced to the individual to which it belongs. Some data is provided consciously by the visitor, while other data is collected in the background by the website.</p>
        <br />
        <p>Rewirefestival.nl ONLINE ENVIRONMENT</p>
        <p>In order to maintain a space in which all individuals feel safe and welcome, visitors are required to sign in on entry of the Rewirefestival.nl online environment. Your email address will not be made public or used for other purposes.</p>
        <p>This website uses Google Analytics, which places analytical cookies to record your site use and stores data about it (including your IP address) on servers in the USA. It uses this data to create statistics for The Rodina, website administrator. Google provides this data to third parties if required by law or if these third parties process the data on behalf of Google. Google does not combine your IP address with other data that it has. By using this website you consent to this data processing by Google.</p>
        <br/>
        <p>SPECIAL DATA PROCESSING</p>
        <p>Rewirefestival.nl does not intend to collect data about website visitors who are younger than 16 years of age, unless they have permission from parents or guardian. However, we cannot check whether a visitor is older than 16. We therefore advise parents to be involved in the online activities of their children, to prevent data about children being collected without parental consent.</p>
        <br/>
        <p>AUTOMATED DECISION MAKING</p>
        <p>Rewirefestival.nl does not make decisions based on automated processing on matters that may have (significant) consequences for individuals. These are decisions that are made by computer programmes or systems, without a human being (such as a The Rodina employee) involved.</p>
        <br/>
        <p>VIEW, MODIFY OR DELETE DATA</p>
        <p>You have the right to view, correct or delete your personal data. You can send a request for access, correction, deletion, data transfer of your personal data or request for withdrawal of your consent or objection to the processing of your personal data to vit@therdoina.com. To ensure that the request for inspection has been made by you, we ask that you send a copy of your proof of identity with the request. In this copy your passport photo, make the MRZ (machine readable zone, the strip with numbers at the bottom of the passport), passport number and citizen service number (BSN) BLACK. This is to protect your privacy. We will respond to your request as quickly as possible, but within four weeks.</p>
        <br/>
        <p>DATA PROTECTION</p>
        <p>Rewirefestival.nl takes the protection of your data seriously and takes appropriate measures to prevent abuse, loss, unauthorised access, unwanted disclosure and unauthorised changes. If you have the impression that your data is not properly protected or there are indications of abuse, please contact us.</p>
        <p>Rewirefestival.nl does not store your personal data for longer than legally required or necessary to achieve the purposes for which the data is collected.</p>
        <p>Rewirefestival.nl does not sell personal data to third parties. Rewirefestival.nl shares personal data with various third parties if this is necessary for the execution of the agreement and to comply with a possible legal obligation. We enter into a data processing agreement with companies that process your data on our behalf to ensure the same level of security and confidentiality of your data. The Rodina remains responsible for these processes.</p>
      </div>

    </div>

  )
}
