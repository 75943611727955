import React from 'react';
import { useOffWorldLiveStream } from '../components/OffWorldLiveStreamProvider';
export const useViewers = () => {
    const stream = useOffWorldLiveStream();
    const [viewers, setViewers] = React.useState(stream.state.viewers);
    React.useEffect(() => {
        stream.onViewerCountChange(setViewers);
        return () => {
            stream.off('viewerCountChange', setViewers);
        };
    }, [setViewers, stream]);
    return viewers;
};
