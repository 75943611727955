import React from 'react'

import classes from "./buttons.scss";

function Close(props) {

    return (
        <div className={props.classes} onClick={props.handleClick}>
            {props.whiteStroke == undefined ?
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.5 25.5">
                    <defs>
                        <style>
                            {
                                ".svg-close{fill:none;stroke:#ff5b4a;stroke-miterlimit:10;stroke-width:2px}"
                            }
                        </style>
                    </defs>
                    <g id="svg1" data-name="Vrstva 2">
                        <g id="svg1">
                            <circle className="svg-close" cx={12.75} cy={12.75} r={11.75} />
                            <path
                                className="svg-close"
                                d="M3.91 3.91l17.68 17.68M4.62 20.88L20.88 4.62"
                            />
                        </g>
                    </g>
                </svg>
                :
                <svg
                    id="prefix__Vrstva_1"
                    xmlns="http://www.w3.org/2000/svg"
                    x={0}
                    y={0}
                    viewBox="0 0 25.5 25.5"
                    xmlSpace="preserve"
                   
                >
                    <style>
                        {
                            ".svg-close-white{fill:none;stroke:#fff;stroke-width:2;stroke-miterlimit:10}"
                        }
                    </style>
                    <g id="svg2">
                        <g id="svg2">
                            <circle className="svg-close-white" cx={12.8} cy={12.8} r={11.8} />
                            <path
                                className="svg-close-white"
                                d="M3.9 3.9l17.7 17.7M4.6 20.9L20.9 4.6"
                            />
                        </g>
                    </g>
                </svg>
            }

        </div>

    )
}


export default Close