import React, { useEffect, useState, useRef } from 'react'
import { findDOMNode } from 'react-dom'
import useProgramme from '../scenes/useProgramme'

import ReactPlayer from 'react-player';
import screenfull from 'screenfull'

import Clifford from '../clifford/Clifford';
import Clap from '../clap/Clap';
import Close from "../ui/CloseButton";
import PrismicSection from '../prismic/PrismicSection'

import classes from './video-window.scss'

function VideoWindow(props) {


     var isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
     // if (isIOS) {
     // console.log('This is a IOS device');
     // } else {
     // console.log('This is Not a IOS device');
     // }

     const player = useRef(null);
     const [url, setUrl] = useState('');

     useEffect(() => {
          if (props.section === 1){
               setUrl(props.videos.video1.url)
          } else {
               setUrl(props.videos.video2.url)
          }
     }, [])

     const channel = () => {
          if (props.section === 1){
               return 'videochannel';
          } else {
               return 'livestream';
          }
     }
     const playingNow = useProgramme(channel());

     const handleClickFullscreen = () => {
          const video = player.current.getInternalPlayer()
          if (screenfull.isEnabled) {
               screenfull.request(video);
          } 
     }

     const fullscreenButton = (
          <svg
               xmlns="http://www.w3.org/2000/svg"
               viewBox="0 0 22.27 22.27"
          >
               <g data-name="Vrstva 2fs">
                    <path
                         fill="#fff"
                         d="M22.27 4.79V0h-4.8l-2.03 2.04h3.38l-7.69 7.68-7.68-7.68h3.38L4.79 0H0v4.79l2.04 2.04V3.45l7.68 7.68-7.68 7.69v-3.38L0 17.47v4.8h4.79l2.04-2.04H3.45l7.68-7.68 7.69 7.68h-3.38l2.03 2.04h4.8v-4.8l-2.04-2.03v3.38l-7.68-7.69 7.68-7.68v3.38l2.04-2.04z"
                         data-name="Ebene 1fs"
                    />
               </g>
          </svg>)

     

     const containerWidth = props.showChat ? ' section-width1' : ' section-width2';
     const marginLeft = props.showChat ? ' section-margin-left1' : ' section-margin-left2';
     const marginRight = props.showChat ? ' section-margin-right1' : ' section-margin-right2';

     const fullscreenLeft = props.showChat ? ' section-fullscreen1' : ' section-fullscreen2';

     return (
          <div className='section-window'>
               <div className={'section-container ' + marginLeft + marginRight + containerWidth}>
                    <div className='section-close-container'>
                         <Close
                              handleClick={props.handleClose}
                              classes={"button-close-section"}
                         />
                    </div>
                    <div className='section-headline-container'>
                         {props.title}
                    </div>
                    <div className={'section-fullscreen-button-container' + fullscreenLeft} onClick={handleClickFullscreen}>
                         {fullscreenButton}
                    </div>
                    <div className='top-line'></div>
                    <div className='section-player-container'>
                         <ReactPlayer
                              ref={player}
                              className='section-player'
                              url={url}
                              muted={props.muted}
                              playing={true}
                              width='100%'
                              height='100%'
                              controls={isIOS}

                              config={{
                                   file: {
                                        
                                       hlsOptions: {
                                           // debug: true,
                                       },
                                       hlsVersion: '1.0.2'
                                   }
                               }}

                         />
                    </div>
                    {props.cliffordSage.open ?
                         <Clifford classes='clifford-container' />
                         : ''}
                    <div className='section-text-container'>
                         {playingNow !== undefined && playingNow !== ''  ?
                              <div >
                                   <div className='section-text-artist'>
                                        {playingNow.name}
                                   </div>
                                   <div className='section-text-time'>
                                        {playingNow.from}-{playingNow.to}
                                   </div>
                                   <div className='section-text-description'>
                                        <PrismicSection uid={playingNow.note} showClose={false} showTitle={false} />
                                   </div>
                              </div>
                              :
                              ""
                         }
                    </div>
               </div>
               <Clap room={props.section} />
          </div>

     )
}

export default VideoWindow