import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { useForm } from "react-hook-form";
import { goBack } from "history";
import "./donate.scss";
import Logo from './ui/Logo'
import Close from './ui/CloseButton'

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const KEY = 'pk_live_51IftLIJlqyR7etgyJh4E6Jurjsxe0LS4z0WmRENLjSotrnMFjxa1rmTQsbrfAf5zw25vDeBaHjPKJLnz51YMGIGn00C3nv2OF5'
// const SERVER = 'http://localhost:5001/rewire-b9de3/europe-west3/charge'
const SERVER = 'https://europe-west3-rewire-b9de3.cloudfunctions.net/charge'


const stripePromise = loadStripe(KEY);


const Message = ({ message }) => (
    <section>
        <p>{message}</p>
    </section>
);

export default function Donate(props) {
    const [message, setMessage] = useState("");
    const [success, setSuccess] = useState(false);
    const [cancel, setCancel] = useState(false);
    const [amount, setAmount] = useState(25)
    const [activeButton, setActiveButton] = useState(2)
    const [showLoading, setShowLoading] = useState(false);
    const {
        setValue,
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({ defaultValues: {}, mode: 'all' })

    useEffect(() => {
        // Check to see if this is a redirect back from Checkout
        const query = new URLSearchParams(window.location.search);
        // console.log(query.toString())
        // console.log(query.has("succes"))

        if (query.has("success")) {
            console.log('Succes')
            setSuccess(true)
        }

        if (query.has("cancel")) {
            setCancel(true)
        }
    }, []);


    const handleClick = async (data) => {
        setShowLoading(true);

        const finalAmount = data.amount ? parseInt(data.amount) : amount

        const stripe = await stripePromise;

        const response = await fetch(SERVER, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                amount: finalAmount * 100,
            })
        });

        const session = await response.json();

        // When the customer clicks on the button, redirect them to Checkout.
        const result = await stripe.redirectToCheckout({
            sessionId: session.id,
        });

        if (result.error) {
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `result.error.message`.
        }
    };

    const handleOnChange = (event) => {
        if (event.target.name === 'amount') {
            const value = event.target.value
            if (value === '') {
                setAmount(25)
                setActiveButton(2)
            } else {
                setAmount(parseInt(value))
                setActiveButton(5)
            }
        }
    }

    return message ? (
        <Message message={message} />
    ) : (

        <section className="donate">
            <Close
                handleClick={() => props.history.push("/scene")}
                classes={"button-close button-right"}
                whiteStroke={true}
            />
            { cancel ? 
                <div>
                    <div>
                        <div class="intro-text border">
                            We are sorry. Your payment was cancel. Please try it
                        </div>
                    </div> 
                </div> 
                : null}
            { success ? 
                <div>
                    <div class="intro-text border">
                        Many thanks for your donation, we really appreciate your support!
                        <br></br>
                    </div>
                    <a class="back-to-front" href="https://live.rewirefestival.nl">
                        Take me back to the virtual festival hub.
                    </a>
                </div> 
            : <div>
                <div>
                <Logo classes={'logo logo-left'} />
                <div className="intro-text border">
                    The online edition of Rewire 2021 is accessible for free but please consider supporting Rewire by making a donation. It helps us to continue investing in emerging artists and the creation of new works.<br></br>
                    <br />
                    <div className="donation-note">Donations are tax-deductible.<br/>
                    Rewire is a registered charitable organization and has the fiscal, cultural ANBI status. A special tax law makes it more advantageous to make a charitable donation. This applies to one-time donations but also recurring gifts. If you choose to support Rewire for a minimum of five years your gift will be fully tax-deductible. If you wish to learn more about the tax law or supporting Rewire in the longer-term please contact us for more information via support[at]rewirefestival[dot]nl.<br></br>
                    </div>
                </div>
                <div className="donate-text">Donate:</div>
                <div className="donation-predefined-container">
                    <AmountButton isActive={activeButton === 1} amount={10} handleClick={() => {
                        setValue('amount', null)
                        setAmount(10)
                        setActiveButton(1)
                    }} />
                    <AmountButton isActive={activeButton === 2} amount={25} handleClick={() => {
                        setValue('amount', null)
                        setAmount(25)
                        setActiveButton(2)
                    }} />
                    <AmountButton isActive={activeButton === 3} amount={50} handleClick={() => {
                        setValue('amount', null)
                        setAmount(50)
                        setActiveButton(3)
                    }} />
                    <AmountButton isActive={activeButton === 4} amount={100} handleClick={() => {
                        setValue('amount', null)
                        setAmount(100)
                        setActiveButton(4)
                    }} />

                    {/* <CustomButton setAmout={setAmount} isActive={activeButton === 5} amount={100} handleClick={() => {
                        setValue('amount', null)
                        setActiveButton(5)
                    }} /> */}

                </div>
                <form onSubmit={handleSubmit(handleClick)} onChange={handleOnChange}>
                    <div className="donate-text">Or:</div>

                    <input className="donate-amount-input donate-custom-amout" type="number" name="amount" defaultValue="test" {...register('amount', { minLength: 1, min: 1, max: 999 })} />

                    {errors.amount && <div className="donate-error">Minimum donation is € 1</div>}
                    {/* <div>E-mail address: 
                        <input name="email" {...register('email', { required: true, pattern: /^\S+@\S+$/i })} />
                        {errors.email && <div>Email adress is requred.</div>}
                    </div> */}
                    <div>
                        <input className="button-checkbox button-checkbox-donate border" type="checkbox" name="consent" {...register('consent', { required: true })} />I agree to the terms and conditions and the privacy statement.
                    {errors.consent && <div className="donate-error">Your agreemnt is required.</div>}
                    </div>

                    <input className="donate-submit border" type="submit" value={"Donate: € " + amount} />
                </form>
            </div>
            </div>}
            <div>
                {showLoading ?
                    <div className='donate-loading-bg'>
                        <div className='donate-loading-wrapper'>
                            <div className='donate-loader'> </div>
                            <div className='donate-loading-text'>Loading</div>
                        </div>
                    </div>

                    : null}
            </div>
        </section>


    );
}

const AmountButton = (props) => {
    const isActiveClass = props.isActive ? 'donation-predefined-amount-active border' : ''

    return (
        <button className={'donation-predefined-amount ' + isActiveClass} onClick={props.handleClick} style={{ whiteSpace: 'nowrap' }}>
            € {props.amount}
        </button>
    )
}

