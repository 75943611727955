import React from 'react'

import classes2 from "../../style/style.scss";

function Logo(props) {

     return (
          <div className={props.classes}>
          </div>

     )
}


export default Logo