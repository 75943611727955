import React from "react";
import Switch from "@material-ui/core/Switch";
import { withStyles } from '@material-ui/core/styles';


const IOSSwitch = withStyles((theme) => ({
  root: {
    width: '36px',
    height: '18px',
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 0,
    
    '&$checked': {
      transform: 'translateX(18px)',
      color: theme.palette.common.white,
      '& + $track': {
        border: `2px solid ${theme.palette.common.white}`,
        backgroundColor: 'rgba(65, 82, 245,1)',
        opacity: 1,
      },
    },

    '&$focusVisible $thumb': {

    },
  },
  thumb: {
    width: '18px',
    height: '18px',
    boxShadow: 'none',
  },
  track: {
    borderRadius: 26 / 2,
    border: `2px solid ${theme.palette.common.white}`,
    backgroundColor: 'rgba(255, 91, 74,1)',
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
    

  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

export default IOSSwitch;