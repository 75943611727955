import React, { useRef, useCallback, useEffect, useState } from 'react'
import { useGesture } from 'react-use-gesture'
import vhCheck from 'vh-check'


// Container dimension observer
import useDimensions from "react-cool-dimensions";

// Custom hooks
import useWindowDimensions from "./useWindowDimensions"

import SliderArrows from '../ui/SliderArrows';
import UserPosition from '../ui/SliderUserPosition';


// Scenes
import VideoOnDemand from "../scenes/VideoOnDemand"
import Portals from "../scenes/Portals"
import Podcast from "../scenes/Podcast"
import VideoChannel from "../scenes/VideoChannel"
import Livestream from "../scenes/Livestream"
import Middle from "../scenes/Middle"
import BorderZone from "../scenes/BorderZone"

import Avatars from './Avatars'


// Classes
import classes2 from './slider.scss';

//  TODO
//  udelat lepi easing funkci zavislou na case ... nebo velocite ..
//  a odelat spring ze zavislosti

export default function Slider(componentProps) {


    const { windowWidth, windowHeight } = useWindowDimensions();

    const [contentWidth, setContentWidth] = useState(0);
    const [scrollPosition, setScrollPosition] = useState(-5000);
    const [increment, setIncrement] = useState(10);

    const { observe, width } = useDimensions({
        onResize: ({ width }) => {
            setContentWidth(width)
        },
    });

    const TRESHOLD = 0.001;


    // 
    // Set position for start
    // 



    useEffect(() => {
        let widthOfScene;
        if (vhCheck().isNeeded){
             widthOfScene = 2.4 * vhCheck().vh;
        } else {
             widthOfScene = 2.4 * window.innerHeight;
        }
        const startPosition = (- 3 * widthOfScene) - (widthOfScene / 2) + (window.innerWidth / 2); 
    
        if (startPosition < 0) {
            setScrollPosition(startPosition-200) // -200 because there is increment 10 on the start
        }
    }, [contentWidth, windowHeight]);

    // Refs for animation frame
    const requestRef = useRef();
    const previousTimeRef = useRef();

    // 
    // Make scroling with break
    // 
    useEffect(() => {

        if (increment > TRESHOLD || increment < -TRESHOLD) {
            const animate = time => {
                if (previousTimeRef.current != undefined) {
                    const deltaTime = time - previousTimeRef.current;
                    if (scrollPosition + increment > 0) {
                        setScrollPosition((- contentWidth / 8 * 7) + increment)
                    }
                    else {
                        if (scrollPosition + increment < (- contentWidth / 8 * 7)) {
                            setScrollPosition(0 + increment)
                        } else {
                            setScrollPosition((curentPosition) => curentPosition + increment)
                        }
                    }
                }
                setIncrement(increment * 0.95)
                previousTimeRef.current = time;
                requestRef.current = requestAnimationFrame(animate);
            }
            requestRef.current = requestAnimationFrame(animate);
        }
        return () => cancelAnimationFrame(requestRef.current);
    }, [increment, contentWidth, windowWidth]);

    const onScroll = useCallback((y, vy) => {
        if(Math.abs(y) > Math.abs(increment)) {
            setIncrement(y)
        }
    }, [increment])

    const wheelOffset = useRef(0)
    const dragOffset = useRef(0)

    const bind = useGesture({
        onDrag: ({ delta: [x], vxvy: [vx] }) => vx && ((dragOffset.current = -x), onScroll(x, vx)),
        onWheel: ({ delta: [x, y], vxvy: [, vy] }) => vy && ((wheelOffset.current = y), onScroll((x+y) / 2, vy))
    },
    )

    const leftArrowClick = () => {
        let widthOfScene;
        if (vhCheck().isNeeded){
             widthOfScene = 2.4 * vhCheck().vh;
        } else {
             widthOfScene = 2.4 * window.innerHeight;
        }
        const shift = (widthOfScene - window.innerWidth) / 2; // Diference between edge of a scene and user position when scene is centered

        // scrollInTotal is total length of scroll after multiplying by increments
        let scrollInTotal;
        let i;
        for (i = -widthOfScene * 7 - shift; i <= widthOfScene * 2 - shift; i += widthOfScene) {
            if (i-150 > scrollPosition){
                scrollInTotal=(i - scrollPosition);
                break;
            }
        }
        // looking for right increment while sum of the increment is lower than scrollInTotal
        let sum = 0;
        let increment = TRESHOLD;
        while (sum+20 < scrollInTotal){
            increment /= 0.95;
            sum += increment;
            
        }
        onScroll(increment, 0);  
    }

    const rightArrowClick =() => {
        let widthOfScene;
        if (vhCheck().isNeeded){
             widthOfScene = 2.4 * vhCheck().vh;
        } else {
             widthOfScene = 2.4 * window.innerHeight;
        }
        // Fix 1.3
        const shift = (widthOfScene - window.innerWidth) / 2; // Diference between edge of a scene and user position when scene is centered

        // scrollInTotal is total length of scroll after multiplying by increments
        let scrollInTotal;
        let i;
        for (i = -shift; i > -widthOfScene * 8; i -= widthOfScene) {
            if (scrollPosition > i+150){
                scrollInTotal=(i - scrollPosition)
                break;
            }
        }
        
        // looking for right increment while sum of the increment is higher than scrollInTotal
        let sum = 0;
        let increment = -TRESHOLD;
        while (sum-20 > scrollInTotal){
            increment /= 0.95;
            sum += increment;
        }
        onScroll(increment, 0);
    }
    

    return (
        <div>
            <UserPosition 
                userPosition={scrollPosition} 
                handlePositionPacking={componentProps.handlePositionPacking}
                positionPacked={componentProps.positionPacked}
                setScrollPosition={setScrollPosition}
                inWindow={componentProps.inWindow}
            /> 

            <SliderArrows
                showChat={componentProps.showChat}
                handleLeftClick={() => leftArrowClick()}
                handleRightClick={() => rightArrowClick()}
            />

            <div className="slider" {...bind()}>
            <div style={{ transform: `translate3d(${scrollPosition}px, 0px, 0px)` }} className="slider-scroll-container" ref={observe}>
                    <img className="slider-section" src="./images/scenes/b_01.jpg" />
                    <img className="slider-section" src="./images/scenes/b_02.jpg" />
                    <img className="slider-section" src="./images/scenes/b_03.jpg" />
                    <img className="slider-section" src="./images/scenes/b_04.jpg" />
                    <img className="slider-section" src="./images/scenes/b_05.jpg" />
                    <img className="slider-section" src="./images/scenes/b_06.jpg" />
                    <img className="slider-section" src="./images/scenes/b_07.jpg" />
                    <img className="slider-section" src="./images/scenes/b_01.jpg" />
                </div>
                
                {/* 
                    We will need to make these bigger because of scroll                   
                */}
                
                <div style={{ transform: `translate3d(${scrollPosition * 1.2}px, 0px, 0px)` }} className="slider-scroll-container slider-scroll-container-middle">
                    <img className="slider-section-middle" src="./images/scenes/c_01.png" />
                    <img className="slider-section-middle" src="./images/scenes/c_02.png" />
                    <img className="slider-section-middle" src="./images/scenes/c_03.png" />
                    <img className="slider-section-middle" src="./images/scenes/c_04.png" />
                    <img className="slider-section-middle" src="./images/scenes/c_05.png" />
                    <img className="slider-section-middle" src="./images/scenes/c_06.png" />
                    <img className="slider-section-middle" src="./images/scenes/c_07.png" />
                    <img className="slider-section-middle" src="./images/scenes/c_01.png" />
                </div>

                {/* 
                    We will need to make these bigger because of scroll                   
                */}
                <div style={{ transform: `translate3d(${scrollPosition * 1.4}px, 0px, 0px)` }} className="slider-scroll-container">
                    <BorderZone/>
                    <VideoOnDemand videoOnDemand={componentProps.videoOnDemand} handleOnClick={componentProps.handleOpenedDialog}/>
                    <Portals activePortals={componentProps.activePortals} handleOnClick={componentProps.handleOpenedSection}/>
                    <Middle handleLandingButton={componentProps.handleOpenedDialog}/>
                    <VideoChannel handleOnClick={componentProps.handleOpenedSection} />
                    <Livestream handleOnClick={componentProps.handleOpenedSection} />
                    <Podcast handleOnClick={componentProps.handleOpenedSection} />
                    <BorderZone handleOnClick={componentProps.handleOpenedSection} />
                </div>

                <div style={{ transform: `translate3d(${scrollPosition}px, 0px, 0px)` }} className="slider-scroll-container">
                    { (componentProps.showAvatars && !componentProps.inWindow &&  window.innerWidth > 600) ?
                    <Avatars user={componentProps.user} scroll={scrollPosition} showAvatars={componentProps.showAvatars} inWindow={componentProps.inWindow}/>
                    : null }
                </div>

                <div style={{ transform: `translate3d(${scrollPosition * 1.6}px, 0px, 0px)` }} className="slider-scroll-container slider-scroll-container-middle">
                    <img className="slider-section-top" src="./images/scenes/d_01.png" />
                    <img className="slider-section-top" src="./images/scenes/d_02.png" />
                    <img className="slider-section-top" src="./images/scenes/d_03.png" />
                    <img className="slider-section-top" src="./images/scenes/d_04.png" />
                    <img className="slider-section-top" src="./images/scenes/d_05.png" />
                    <img className="slider-section-top" src="./images/scenes/d_06.png" />
                    <img className="slider-section-top" src="./images/scenes/d_07.png" />
                    <img className="slider-section-top" src="./images/scenes/d_01.png" />
                </div>


            </div>
        </div>
    )
}


// const easing = {
//     linear: (n) => n,
//     elastic: (n) =>
//       n * (33 * n * n * n * n - 106 * n * n * n + 126 * n * n - 67 * n + 15),
//     inExpo: (n) => Math.pow(2, 10 * (n - 1)),
//   };