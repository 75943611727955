import React, { useState, useEffect } from 'react'
import { get } from 'react-hook-form';
import firebase from '../utils/firebase'

import useProgramme from '../scenes/useProgramme'


import Close from "../ui/CloseButton";
import PrismicTimetable from '../prismic/PrismicTimetable'
import "./timetable.scss";
import "../prismic/prismic.scss";
import { array } from 'prop-types';

function Timetable(props) {

    const [activeTab, setActiveTab] = useState(0);
    const [activeDay, setActiveDay] = useState('fri7');
    const [showArtist, setShowArtist] = useState(false);
    const [currentArtist, setCurrentArtist] = useState(null);
    const [loader, setLoader] = useState(true);

    const playingNow1 = useProgramme('videochannel');
    const playingNow2 = useProgramme('livestream');
    const playingNow3 = useProgramme('radio');

    const [timetable, setTimetable] = useState(
        {
            thu6: { livestream: [], radio: [], videochannel: [], portals: [] },
            fri7: { livestream: [], radio: [], videochannel: [], portals: [] },
            sat8: { livestream: [], radio: [], videochannel: [], portals: [] },
            sun9: { livestream: [], radio: [], videochannel: [], portals: [] },
        }
    )

    const setArtist = (uid) => {
        setShowArtist(true);
        setCurrentArtist(uid);
    }

    useEffect(() => {
        let timetableRef = firebase.database().ref("timetable/");
        timetableRef.on("value", (snapshot) => { // to je callback vzdy se pusti kdyz neco zmenis v DB
            setLoader(true);
            let newTimetable = {
                thu6: {
                    livestream: (JSON.parse(snapshot.val().thu6.livestream)),
                    videochannel: (JSON.parse(snapshot.val().thu6.videochannel)),
                    radio: (JSON.parse(snapshot.val().thu6.radio)),
                    portals: (JSON.parse(snapshot.val().thu6.portals)),
                },
                fri7: {
                    livestream: (JSON.parse(snapshot.val().fri7.livestream)),
                    videochannel: (JSON.parse(snapshot.val().fri7.videochannel)),
                    radio: (JSON.parse(snapshot.val().fri7.radio)),
                    portals: (JSON.parse(snapshot.val().fri7.portals)),
                },
                sat8: {
                    livestream: (JSON.parse(snapshot.val().sat8.livestream)),
                    videochannel: (JSON.parse(snapshot.val().sat8.videochannel)),
                    radio: (JSON.parse(snapshot.val().sat8.radio)),
                    portals: (JSON.parse(snapshot.val().sat8.portals)),
                },
                sun9: {
                    livestream: (JSON.parse(snapshot.val().sun9.livestream)),
                    videochannel: (JSON.parse(snapshot.val().sun9.videochannel)),
                    radio: (JSON.parse(snapshot.val().sun9.radio)),
                    portals: (JSON.parse(snapshot.val().sun9.portals)),
                },
            }
            setTimetable(newTimetable);
            setLoader(false);
        })

        let date = new Date();
        let day = date.getUTCDate();
        switch (day) {
            case 6:
                setActiveTab(0);
                setActiveDay('thu6');
                break;
            case 7:
                setActiveTab(1);
                setActiveDay('fri7');
                break;
            case 8:
                setActiveTab(2);
                setActiveDay('sat8');
                break;
            case 9:
                setActiveTab(3);
                setActiveDay('sun9');
                break;
            default:
                setActiveTab(0);
                setActiveDay('thu6');
        }

        return (() => timetableRef.off('value'));

    }, [])

    // Change active tab
    const changeActive = (i) => {
        switch (i) {
            case 0:
                setActiveTab(0);
                setActiveDay('thu6');
                break;
            case 1:
                setActiveTab(1);
                setActiveDay('fri7');
                break;
            case 2:
                setActiveTab(2);
                setActiveDay('sat8');
                break;
            case 3:
                setActiveTab(3);
                setActiveDay('sun9');
                break;
            default:
                setActiveTab(0);
                setActiveDay('thu6');
        }
    }

    return (
        <div>
            <div className="timetable-container">

                {loader ?
                    <div className='timetable-loading-wrapper'>
                        <div className='timetable-loader'> </div>
                        <div className='timetable-loading-text'>Loading</div>
                    </div>
                    :
                    <div className='timetable-scroll-container'>
                        <div className='timetable-scroll'>
                            <div className='timetable-border-left'></div>

                            <div className='timetable-columns'>
                                <TimetableContent title={'Channel\u00a0I'} classCategory=' timetable-category-red' classText=' timetable-text-red'
                                    active={activeTab} timetable={timetable[activeDay]['videochannel']}
                                    red={true} setArtist={setArtist} playing={playingNow1} activeDay={activeDay}/>
                                <TimetableContent title={'Channel\u00a0II'} classCategory=' timetable-category-red2' classText=' timetable-text-red2'
                                    active={activeTab} timetable={timetable[activeDay]['livestream']}
                                    red={true} setArtist={setArtist} playing={playingNow2}  activeDay={activeDay}/>
                                <TimetableContent title='Radio' classCategory=' timetable-category-red2' classText=' timetable-text-red2'
                                    active={activeTab} timetable={timetable[activeDay]['radio']}
                                    red={true} setArtist={setArtist} playing={playingNow3}  activeDay={activeDay}/>
                                <TimetableContent title='Portals' classCategory=' timetable-category-blue' classText=' timetable-text-blue'
                                    active={activeTab} timetable={timetable[activeDay]['portals']}
                                    red={false} setArtist={setArtist}  activeDay={activeDay}/>
                            </div>

                            <div className='timetable-border-right'></div>
                        </div>
                    </div>
                }

                <div className='timetable-fixed'>
                    <TimetableTab text={'THU\u00a06'} active={activeTab} position={0} handleClick={(i) => changeActive(i)} />
                    <TimetableTab text={'FRI\u00a07'} active={activeTab} position={1} handleClick={(i) => changeActive(i)} />
                    <TimetableTab text={'SAT\u00a08'} active={activeTab} position={2} handleClick={(i) => changeActive(i)} />
                    <TimetableTab text={'SUN\u00a09'} active={activeTab} position={3} handleClick={(i) => changeActive(i)} />
                    <div className='timetable-close'>
                        <Close handleClick={props.handleClick} classes={"button-close button-right"} />
                    </div>
                </div>
            </div>
            {showArtist && currentArtist !== '' ?
                <div>
                    <PrismicTimetable handleClick={() => setShowArtist(false)} uid={currentArtist} />
                </div>
                :
                ""
            }

        </div>
    )
}

export default Timetable

function TimetableTab(props) {
    return (
        <div className={props.position === props.active ? 'timetable-tab timetable-tab-active' : 'timetable-tab timetable-tab-inactive'} onClick={() => props.handleClick(props.position)}>
            {props.text}
        </div>
    )
}

function TimetableContent(props) {
    const timeCheck = (timeFrom, timeTo, activeDay) =>  {
        //check if it is right time
        let dateFrom = Date.parse('02 Jan 2000 ' + timeFrom);
        let dateTo = Date.parse('02 Jan 2000 ' + timeTo);
        if (dateFrom > dateTo){
            dateTo = Date.parse('03 Jan 2000 ' + timeTo);
        }

        
        let date = new Date();
        let hoursNow = date.getUTCHours() + 2;
        let minutesNow = date.getUTCMinutes();

        if (hoursNow >= 24){
            var dateNow = Date.parse('03 Jan 2000 ' + hoursNow%24 + ':' + minutesNow);
        } else {
            var dateNow = Date.parse('02 Jan 2000 ' + hoursNow + ':' + minutesNow);
        }
    
        let goodTime
        if (dateFrom <= dateNow && dateNow < dateTo) {
            goodTime = true;
        } else {
            goodTime = false;
        }
    

        //check if it is right day
        let base = 6;
        
        let dayNow = date.getUTCDate();
        let days = ['thu6', 'fri7', 'sat8', 'sun9'];
        let goodDay;
        if (activeDay === days[dayNow - base]){
            goodDay = true;
        } else {
            goodDay = false;
        }
    
        // return true if it is right day and right time
        return (goodDay && goodTime);
    
    }


    return (
        <div className='timetable-column'>
            <div className={'timetable-category' + props.classCategory}>
                {props.title}
            </div>

            <div >
                {props.timetable.map((array) =>
                    <div className={'timetable-text ' + props.classText} key={array.name + array.from}
                        onClick={() => props.setArtist(array.note)}>
                        <div className={'timetable-link'}>


                            {props.red ?
                                <div className={'timetable-text-date ' + (array.note === props.playing.note && timeCheck(array.from, array.to, props.activeDay) ? 'timetable-text-date-live' : '')}>
                                    {array.from} — {array.to} CEST {array.note === props.playing.note && timeCheck(array.from, array.to, props.activeDay)? <div className='timetable-live'></div> : ''}
                                </div>
                                :
                                ""
                            }

                            <div className='timetable-text-artist'>
                                {array.name}
                            </div>
                        </div>
                    </div>

                )}
            </div>
        </div >
    )
}

