import React, { useState, useEffect } from "react";
import useProgramme from './useProgramme'

import classes2 from "../scene/slider.scss";


function Podcast(props) {

    const playingNow = useProgramme('radio');


    const play = (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 77.87 77.87"
        >
            <g data-name="Vrstva 2">
                <circle
                    cx={38.94}
                    cy={38.94}
                    r={37.94}
                    fill="none"
                    stroke="#fff"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                />
                <path
                    d="M56.14 36.56L31.89 16.85A3 3 0 0027 19.18V58.6a3 3 0 004.89 2.33l24.25-19.71a3 3 0 000-4.66z"
                    fill="#fff"
                />
            </g>
        </svg>
    )


    return (

        <div className="slider-section-content">

            <div className="slider-audio-container" onClick={() => props.handleOnClick(3)}>
                <div className='slider-play'>
                    {play}
                </div>
                <div className='slider-audioplayer-container'>
                    
                </div>
                <div className='slider-text-container'>
                    <div className='slider-headline'>
                        Radio
                    </div>
                    <div className='slider-description'>
                    {playingNow !== undefined && playingNow !== ''  ?
                            <div>
                                {playingNow.name}
                            </div>
                            :
                            ''
                        }
                    </div>
                </div>
            </div>

            <div className="slider-scroll-container-bottom">
                {/* <img src="./images/scenes/b04.png" className={"section-image"} /> */}
            </div>
        </div>
    );
}

export default Podcast;

