import React, { useRef, useCallback, useEffect, useState } from 'react'
import _ from 'lodash';

import * as Colyseus from "colyseus.js";
import settings from '../../settings.json'

const URL = settings.colyseusUrl;


function useAvatars(user, scroll) {
     const [avatars, setAvatars] = useState([])
     const scrollRef = useRef(scroll)
     const roomRef = useRef(null)
     const lisenerRef = useRef(null)
     const mountedRef = useRef(true)

     useEffect(() => {
          scrollRef.current = scroll
     }, [scroll])

     useEffect(() => {
          console.log('Connecting ' + settings.colyseusUrl)
          const client = new Colyseus.Client(URL);
          mountedRef.current = true


          const updatePosition = (x, y) => {
               let payload = {
                    px: x - scrollRef.current,
                    py: y,
               };
               roomRef.current?.send("position", payload);
          };

          lisenerRef.current = _.throttle((event) => {
               updatePosition(event.clientX, event.clientY)
          }, 500);
          window.addEventListener('mousemove', lisenerRef.current);
          let roomToConnect = 1
          client.getAvailableRooms()
               .then(rooms => {
                    rooms.some((room) => {
                         console.log(`In room ${room.name} with ID ${room.roomId} are ${room.clients} clients.`);
                         if (room.clients + 5 < room.maxClients) {
                              roomToConnect = room.name
                              return true
                         }
                    });
                    return  client.joinOrCreate(roomToConnect, { profileURL: user.photoURL, profileName: user.displayName })
               })
               .then(room => {

                    console.log("Joined room " + room.name + " with " + room.sessionId + " " + room.id);
                    roomRef.current = room
                    if (mountedRef.current) {
                         roomRef.current.onStateChange((state) => {
                              const addr = Array.from(state.players.$items, ([key, value]) => ({ key, value }));
                              setAvatars(addr)
                         });
                    }

               }).catch(e => {
                    console.log("JOIN ERROR", e);
               });

          return () => {
               console.log('Disconecting')
               if (roomRef.current) {
                    roomRef.current.removeAllListeners()
               }
               window.removeEventListener('mousemove', lisenerRef.current);
               roomRef.current?.leave()
               mountedRef.current = false
               roomRef.current = null
               lisenerRef.current = null
          }
     }, [user, user.displayName, user.photoURL])

     return avatars
}


export default useAvatars