import React , { Suspense } from 'react'

import classes2 from "./buttons.scss";

function DonateButton (props) {
      return (
        <div>
            <a onClick={ ()=> props.history.push('/donate') } className={"button-square button-white border" + (props.inWindow ? ' button-donate-inwindow' : ' button-donate')}>Donate</a>
        </div>
      )
  }
  
  
  export default DonateButton
