var IdleState;
(function (IdleState) {
    IdleState[IdleState["Idle"] = 0] = "Idle";
    IdleState[IdleState["Active"] = 1] = "Active";
})(IdleState || (IdleState = {}));
export const idleWatcher = (onActive, onInactive, timeout) => {
    let s = IdleState.Active;
    let timer = 0;
    const startTimer = () => {
        timer = window.setTimeout(() => {
            if (s === IdleState.Active) {
                s = IdleState.Idle;
                onInactive();
            }
        }, timeout);
    };
    const handleMovement = () => {
        clearTimeout(timer);
        if (s === IdleState.Idle) {
            onActive();
            s = IdleState.Active;
        }
        startTimer();
    };
    window.addEventListener('mousemove', handleMovement);
    window.addEventListener('mousedown', handleMovement);
    window.addEventListener('scroll', handleMovement);
    window.addEventListener('keydown', handleMovement);
    startTimer();
};
