import React from 'react'
import { Link } from "react-router-dom";

import MuteButton from "../ui/MuteButton";
import Close from '../ui/CloseButton'


import classes from "./player.scss";


export default function Player(props) {


     return (
          <div className="player-container">

               <Link to="/" onClick={props.handleClick}>
                    <Close classes={"button-close button-right"} />
               </Link>

               <div className="">
                    Player for users with low connection
               </div>

          </div>

     )

}


