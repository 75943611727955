import React from "react";

import classes from "../scene/slider.scss";
import classes2 from '../ui/buttons.scss'


function Middle(props) {
    return (
        <div className="slider-section-content">
            {LandingButton("slider-landing1", () => props.handleLandingButton(10), 'JOIN OUR COMMUNITY', 'Chat  and interact', 'slider-landing-leftrect-1', 'slider-landing-rightrect-1')}
            {LandingButton("slider-landing2", () => props.handleLandingButton(11), 'INTRODUCTION', 'Read our welcome message', 'slider-landing-leftrect-2', 'slider-landing-rightrect-2')}
            {LandingButton("slider-landing3", () => props.handleLandingButton(2), 'HOW TO', 'Answer to all your questions', 'slider-landing-leftrect-3', 'slider-landing-rightrect-3')}
        </div>
    );
}

export default Middle;


function LandingButton(classes, link, title, text, leftRect, rightRect) {
    return (
        <div className={classes + " slider-landing"} onClick={link}>
            <div className={leftRect}></div>
            <div className={rightRect}></div>
            <div className='slider-landing-text'>
                {title} <br></br>
                {text}
            </div>

        </div>
    )
}