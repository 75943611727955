import { useState, useEffect } from 'react';
import firebase from '../utils/firebase'

const UPDATE_INTERVAL = 10000

function useProgramme(channelname) {
        const [playingNow, setPlayingNow] = useState('');
        const [timetable, setTimetable] = useState(
            {
                thu6: { livestream: [], radio: [], videochannel: [], portals: [] },
                fri7: { livestream: [], radio: [], videochannel: [], portals: [] },
                sat8: { livestream: [], radio: [], videochannel: [], portals: [] },
                sun9: { livestream: [], radio: [], videochannel: [], portals: [] },
            }
    )

    useEffect(() => {
        let timetableRef = firebase.database().ref("timetable/");
          timetableRef.on("value", (snapshot) => { // to je callback vzdy se pusti kdyz neco zmenis v DB
               let newTimetable = {
                    thu6: {
                         livestream: (JSON.parse(snapshot.val().thu6.livestream)),
                         videochannel: (JSON.parse(snapshot.val().thu6.videochannel)),
                         radio: (JSON.parse(snapshot.val().thu6.radio)),
                    },
                    fri7: {
                         livestream: (JSON.parse(snapshot.val().fri7.livestream)),
                         videochannel: (JSON.parse(snapshot.val().fri7.videochannel)),
                         radio: (JSON.parse(snapshot.val().fri7.radio)),
                    },
                    sat8: {
                         livestream: (JSON.parse(snapshot.val().sat8.livestream)),
                         videochannel: (JSON.parse(snapshot.val().sat8.videochannel)),
                         radio: (JSON.parse(snapshot.val().sat8.radio)),
                    },
                    sun9: {
                         livestream: (JSON.parse(snapshot.val().sun9.livestream)),
                         videochannel: (JSON.parse(snapshot.val().sun9.videochannel)),
                         radio: (JSON.parse(snapshot.val().sun9.radio)),
                    },
               }
               setTimetable(newTimetable)
            //    console.log('New timetable came')
          });
          return (() => {
            //   console.log('Disconecting timetable')
              timetableRef.off('value')
            });
    }, [])
  
    useEffect(() => {
        // console.log('Connecting Tick');
        const newPlayingNow = whoIsPlaying(timetable, channelname)
        if (newPlayingNow !==  playingNow) {
            // console.log('Updating text')
            setPlayingNow(newPlayingNow ) 
        }
        
        const interval = setInterval(() => {

            // console.log(channelname);
            // console.log(whoIsPlaying(timetable, channelname))

            const newPlayingNow = whoIsPlaying(timetable, channelname)
            if (newPlayingNow !==  playingNow) {
                // console.log('Updating text')
                setPlayingNow(newPlayingNow ) 
            }
          }, UPDATE_INTERVAL);
          return () => {
            //   console.log('interval removed')
              clearInterval(interval)
            };
    }, [timetable, playingNow])
  
    return playingNow;
  }

  export default useProgramme


 function compareTimes(timeFrom, timeTo, hoursNow, minutesNow) {
    let dateFrom = Date.parse('02 Jan 2000 ' + timeFrom);
    let dateTo = Date.parse('02 Jan 2000 ' + timeTo);
    if (dateFrom > dateTo){
        dateTo = Date.parse('03 Jan 2000 ' + timeTo);
    }

    if (hoursNow >= 24){
        var dateNow = Date.parse('03 Jan 2000 ' + hoursNow%24 + ':' + minutesNow);
    } else {
        var dateNow = Date.parse('02 Jan 2000 ' + hoursNow + ':' + minutesNow);
    }

    
    if (dateFrom <= dateNow && dateNow < dateTo) {
        return 1;
    } else {
        return 0;
    }
}


   function whoIsPlaying (timetable, channelname) {
      
        const days = ['thu6', 'fri7', 'sat8', 'sun9'];
        let date = new Date();
        let day = date.getUTCDate();
        let hours = date.getUTCHours() + 2;
        let minutes = date.getUTCMinutes();

        let i, j;
        let base = 6;
        for (i = base; i <= base + 3; i++) { //i from 6 to 9
            if (day === i) {
                let todayTimetable = timetable[days[i - base]][channelname]; //prepsat 0 na i-6
                for (j = 0; j < todayTimetable.length; j++) {

                    let fromHours = todayTimetable[j]['from'].substring(0, 2); //in UTCS
                    let fromMinutes = todayTimetable[j]['from'].substring(3, 5);
                    let from = fromHours + ':' + fromMinutes;
                    let toHours = todayTimetable[j]['to'].substring(0, 2);
                    let toMinutes = todayTimetable[j]['to'].substring(3, 5);
                    let to = toHours  + ':' + toMinutes;
                  

                    if (compareTimes(from, to, hours, minutes) === 1){
                        return(todayTimetable[j]);
                    } else if (j == todayTimetable.length-1) {
                        return('');
                    }
                }
            }
        }
        return('');
    }