import React from 'react'
import VideoWindow from './VideoWindow'
import AudioWindow from './AudioWindow'


import MusicForABusyCity from '../portals/MusicForABusyCity'
import Pussykrew from '../portals/Pussykrew'
import MSHR from '../portals/MSHR'
import ShowPonyTheRodina from '../portals/ShowPonyTheRodina'
import LisaHall from '../portals/LisaHall'
import SecretPage from '../portals/SecretPage'
import NYX from '../portals/NYX'
import VideoOnDemand from '../videoOnDemand/VideoOnDemand'

import classes2 from "./video-window.scss";
import classes3 from '../ui/buttons'


export default function SectionWindow(props) {

     return (
          <div>


               {props.section == 1 ?
                    <VideoWindow
                         title="Video Channel I"
                         section={props.section}
                         videos={props.videos}
                         muted={props.muted}
                         showChat={props.showChat}
                         cliffordSage={props.cliffordSage}
                         handleClose={props.handleClose}
                    />
                    : null}

               {props.section == 2 ?
                    <VideoWindow
                         title="Video Channel II"
                         section={props.section}
                         videos={props.videos}
                         muted={props.muted}
                         showChat={props.showChat}
                         cliffordSage={props.cliffordSage}
                         handleClose={props.handleClose}
                    />
                    : null}
               {props.section == 3 ?
                    <AudioWindow
                         section={props.section}
                         audio={props.audio}
                         muted={props.muted}
                         showChat={props.showChat}
                         handleClose={props.handleClose}
                    />
                    : null}

               {props.section == 4 ?
                    <MusicForABusyCity handleClose={props.handleClose} showChat={props.showChat} />
                    : null}
               {props.section == 5 ?
                    <MSHR handleClose={props.handleClose} showChat={props.showChat} />
                    : null}
               {props.section == 6 ?
                    <Pussykrew handleClose={props.handleClose} showChat={props.showChat} />
                    : null}
               {props.section == 7 ?
                    <ShowPonyTheRodina handleClose={props.handleClose} showChat={props.showChat} />
                    : null}
               {props.section == 8 ?
                    <LisaHall handleClose={props.handleClose} showChat={props.showChat} />
                    : null}
               {props.section == 9 ?
                    <SecretPage handleClose={props.handleClose} showChat={props.showChat} muted={props.muted}/>
                    : null}
               {props.section == 10 ?
                    <NYX handleClose={props.handleClose} showChat={props.showChat} />
                    : null}
               {props.section == 11 ?
                    <VideoOnDemand handleClose={props.handleCloseSectionVod} showChat={props.showChat} array={props.array} muted={props.muted} day={props.day}/>
                    : null}
          </div>
     )
}
