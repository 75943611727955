import React from 'react'

import classes2 from "./buttons.scss";

function OverviewButton(props) {
  return (
    <div className="button-square button-white button-overview border" onClick={props.handleClick}>
      	?
    </div>
  )
}


export default OverviewButton