import React, { useState } from "react";
import ProfileButton from "./ui/ProfileButton";
import MuteButton from "./ui/MuteButton";
import ExitButton from "./ui/ExitButton";
import IOSSwitch from './ui/IOSSwitch';

import classes from "./chat/chat.scss";
import classes2 from "./logged.scss";
import classes3 from "./ui/buttons";

function Footer(props) {

    const [chat, setChat] = useState(true);
    const [avatar, setAvatar] = useState(true);

    const handleChatSwitch = () => {
        props.handleChatToggle(!chat);
        setChat(!chat);
    }

    const handleAvatarSwitch = () => {
        props.handleAvatarsToggle(!avatar);
        setAvatar(!avatar);
    }

    return (
        <div>
            <div>
                <MuteButton handleClick={props.handleMute} text={props.muted ? "Unmute" : "Mute"} inWindow={props.inWindow} />

                <div className={props.muted ?
                    "footer-control-panel footer-left footer-muted border"
                    : "footer-control-panel footer-left footer-unmuted border"}
                >
                    <div className="footer-profile" onClick={props.handleProfileOpen}>
                        <img src={props.user.photoURL} />
                    </div>
                    <ProfileButton
                        handleClick={props.handleProfileOpen}
                        user={props.user}
                    />
                    <ExitButton handleClick={props.handleExit} />

                </div>

                {props.openedSection !== 9 ?
                    <div className="footer-control-panel footer-right border">
                        <div className="button-toggle button-toggle-avatar" onClick={handleAvatarSwitch}>
                            <label className="button-switch">
                                <IOSSwitch checked={avatar} onChange={handleAvatarSwitch} name="checkedC" />
                            </label>
                            <span>Avatars</span>
                        </div>

                        <div className="button-toggle button-toggle-chat" onClick={handleChatSwitch}>

                            <label className="button-switch">
                                <IOSSwitch checked={chat} onChange={handleChatSwitch} name="checkedC" />
                            </label>
                            <span>Chat</span>
                        </div>
                    </div>
                    : ""}
            </div>
        </div>
    );

}

export default Footer;
