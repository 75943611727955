import React from 'react';
import { useOffWorldLiveStream } from '../components/OffWorldLiveStreamProvider';
export const useCoolingDown = (reactionName) => {
    const stream = useOffWorldLiveStream();
    const [coolingDown, setCoolingDown] = React.useState(false);
    React.useEffect(() => {
        if (!stream) {
            return;
        }
        const handleCoolDownChange = (r, isCooling) => {
            if (r !== reactionName) {
                return;
            }
            setCoolingDown(isCooling);
        };
        stream.onCoolDownChange(handleCoolDownChange);
        return () => {
            stream.off('coolDownChange', handleCoolDownChange);
        };
    }, [setCoolingDown, stream]);
    return coolingDown;
};
