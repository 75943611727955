import React from "react"

import class2 from './clifford.scss'

const Percents = function(props) {
    return (
        <div style={{width: '100%', height: '10px'}}>
            <div style={{width: props.percentComplete, background: 'rgb(255, 91, 74)',  height: '10px'}}>
                
            </div>
        </div>
    )
}

export default Percents