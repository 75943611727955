import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";


import settings from '../../settings.json'

const config = {
    apiKey: settings.apiKey,
    authDomain: settings.authDomain,
    databaseURL: settings.databaseURL,
    storageBucket: settings.storageBucket,
};

const efirebase = firebase.initializeApp(config) 
export default efirebase;