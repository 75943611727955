import React, { useState, useEffect } from "react";

import classes from "../scene/slider.scss";
import classes3 from '../ui/buttons.scss'

function Portals(props) {

    const [portal1, setPortal1] = useState(props.activePortals.portal1);
    const [portal2, setPortal2] = useState(props.activePortals.portal2);
    const [portal3, setPortal3] = useState(props.activePortals.portal3);
    const [portal4, setPortal4] = useState(props.activePortals.portal4);
    const [portal5, setPortal5] = useState(props.activePortals.portal5);
    const [portal6, setPortal6] = useState(props.activePortals.portal6);
    const [portal7, setPortal7] = useState(props.activePortals.portal7);

    useEffect(() => {
        setPortal1(props.activePortals.portal1);
        setPortal2(props.activePortals.portal2);
        setPortal3(props.activePortals.portal3);
        setPortal4(props.activePortals.portal4);
        setPortal5(props.activePortals.portal5);
        setPortal6(props.activePortals.portal6);
        setPortal7(props.activePortals.portal7);


    }, [props.activePortals.portal1.open,
    props.activePortals.portal2.open,
    props.activePortals.portal3.open,
    props.activePortals.portal4.open,
    props.activePortals.portal5.open,
    props.activePortals.portal6.open,
    props.activePortals.portal7.open,
    ])

    return (
        <div className="slider-section-content">
            {Portal("slider-portal1", portal1, () => props.handleOnClick(4), 'Music for a Busy City (online version): Ain Bailey, Genevieve Murphy, Pete Harden, Yannis Kyriakides', "./images/portals/music-in-the-city.jpg", "./images/portals/portal1.svg")}
            {Portal("slider-portal2", portal2, () => props.handleOnClick(5), 'MSHR - Instance Terrain Crawler', "./images/portals/mshr.jpg" , "./images/portals/portal2.svg")}
            {Portal("slider-portal3", portal3, () => props.handleOnClick(6), 'Pussykrew - Serpent of Self', "./images/portals/pussykrew.png", "./images/portals/portal3.svg")}
            {Portal("slider-portal4", portal4, () => props.handleOnClick(7), 'Show Pony & The Rodina', "./images/portals/pony.jpg" , "./images/portals/portal4.svg")}
            {Portal("slider-portal5", portal5, () => props.handleOnClick(8), 'Acts of Air: Reshaping the urban sonic - An online exhibition for offline participation', "./images/portals/acts-of-air.jpg", "./images/portals/portal5.svg")}
            {Portal("slider-portal6", portal6, () => props.handleOnClick(9), 'AM Kanngieser - Between Death And Dying There Is A Hum', "./images/portals/am-kanngieser.jpg", "./images/portals/portal6.svg")}
            {Portal("slider-portal7", portal7, () => props.handleOnClick(10), 'NYX - Mutualism feat. MA.MOYO & Nick Cobby', "./images/portals/mutualism.jpg" , "./images/portals/portal7.svg")}
        </div>
    );
}

export default Portals;


function Portal(classes, portal, link, text, img, bg) {
    return (
        <div>
            <div>
                {portal.open == true ?
                    <div className={classes + " slider-portal"} onClick={link}>
                                <img className='slider-portal-image' src={img} />
                                <div className='slider-portal-text'> 
                                    {text} 
                                </div>
                                <img className='slider-portal-background' src={bg}></img>
                                                  
                    </div>
                    :
                    <div className={classes + " slider-portal slider-portal-inactive border button-gray"}>
                        Closed
                    </div>
                }
            </div>
        </div>
    )
}