import React from "react";

import classes2 from "../scene/slider.scss";

function BorderZone(props) {
    return (
        <div className="slider-section-content">
        </div>
    );
}

export default BorderZone;
