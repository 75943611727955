import React, { useState } from 'react';
import Close from "../ui/CloseButton";
import firebase from '../utils/firebase'
import validator from 'validator'

import MailchimpSubscribe from "../utils/mailchimpSubscribe"

import classes from './welcome.scss'
import classes2 from '../ui/buttons.scss'


// const url = "//xxxx.us13.list-manage.com/subscribe/post?u=zefzefzef&id=fnfgn";
const url = "https://rewirefestival.us14.list-manage.com/subscribe/post?u=7c8324d9b24e367d4805fdb18&amp;id=03f219eb8d";

// simplest form (only email)
const SimpleForm = () => <MailchimpSubscribe url={url}/>

export default function Welcome(props) {

     const [value, setValue] = useState('');
     const [checked, setChecked] = useState(false);
     const [warning, setWarning] = useState(false);
     const [invalidFormat, setInvalidFormat] = useState(false);

     const changeEmail = (event) => {
          if (event.target.value.length <= 40) {
               let newEmail = event.target.value.replace(/\s/g, '')
               setValue(newEmail);
          }
     }

     const handleChecked = () => {
          if (!checked) {
               setWarning(false);
          }
          setChecked(!checked)
     }

     const emailSubmit = (e) => {
          e?.preventDefault();
          props.handleClick();

          // if (value === '') {
          //      props.handleClick();
          // } else {
          //      if (!checked) {
          //           setWarning(true);
          //      } 
          //      if (!validator.isEmail(value)) {
          //           setInvalidFormat(true);
          //      } else {
          //           setInvalidFormat(false);
          //      }
          //      if (checked && validator.isEmail(value)){
          //           firebase.database().ref("emails/").push(value);
          //           props.handleClick();
          //      }
          // }
     }

     return (
          <div className="welcome-container">
               <Close handleClick={props.handleClick} classes={"button-close button-right"} />

               <div className='welcome-content'>
                    <div className="welcome-headline">
                         Welcome!
                    </div>
                    <div className="welcome-text">
                         Welcome to Rewire 2021 – online edition <br />
                         To learn more about how to use this space please see <span onClick={props.handleFAQ} className='welcome-faq'>FAQ</span>

                    </div>
                    <div className="welcome-text-newsletter">
                         Add me to the Rewire newsletter
                    </div>



                    <MailchimpSubscribe url={url}  onSubmit={emailSubmit}/>

                         {/* <label>
                              <div className="welcome-text-newsletter">
                                   Add me to the Rewire newsletter
                               </div>

                              {/* <input type="text" placeholder={"e-mail"} className="welcome-input" onChange={changeEmail} value={value} /> */}
                              
                              {/* <div className="welcome-text-newsletter2">

                                   <input type="checkbox" className="button-checkbox button-checkbox-welcome border-red" checked={checked} onChange={handleChecked} />
                                   I agree to receive emails from Rewire
                              </div> */} 
                              {/* <div className='welcome-warning'>
                                   {invalidFormat ?
                                        <div>
                                             Your e-mail has invalid format
                                        </div>

                                        :
                                        ""
                                   }
                                   {warning ?
                                        <div>
                                             You need to agree with getting ReWire e-mail
                                        </div>
                                        :
                                        ""}
                              </div> */}

                              

                              {/* <div onClick={emailSubmit} className="welcome-continue">
                                   Continue
                              </div> */}



               </div>
          </div>
     )
}