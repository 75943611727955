import React, { useState } from 'react'

import classes from "./buttons.scss";

function CharacterButton(props) {

  function changeValueLeft() {
    props.handleClick(-1);
  }

  function changeValueRight() {
    props.handleClick(1);
  }

  const link = "https://ams3.digitaloceanspaces.com/rewire/middle/middle_" + props.value + ".png";



  // function getPath() {
  //   if (props.position === 0) {
  //     return ("https://rewire2021.netlify.app/" + props.value + ".svg");
  //   } else if (props.position === 1) {
  //     return ("https://rewire2021.netlify.app/" + props.value + ".png");
  //   } 
  // }

  return (
    <div >
      <div className="button-arrow button-arrow-left" onClick={changeValueLeft}>←</div>
      <div className="button-arrow button-arrow-right" onClick={changeValueRight}>→</div>
      <div className='character-image-container'>
      <img src={link} className="character-variable" onClick={changeValueRight}/>
      </div>
      
    </div>
  )
}

export default CharacterButton