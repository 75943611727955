import React, { Suspense } from 'react'
import { Link } from "react-router-dom";

import classes from "./buttons.scss";

function ExitButton(props) {
  return (
      <Link className="button-basic" to="/" onClick={props.handleClick}>
        Exit
      </Link>
  )
}


export default ExitButton