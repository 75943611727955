import React from 'react'
import Close from '../ui/CloseButton'

import classes from "./portals.scss"
import classes2 from '../ui/buttons'
import classes3 from "../window/video-window.scss";


export default function PussyKrew(props) {

     const containerWidth = props.showChat ? ' section-width1' : ' section-width2';
     const marginLeft = props.showChat ? ' section-margin-left1' : ' section-margin-left2';
     const marginRight = props.showChat ? ' section-margin-right1' : ' section-margin-right2';



     return (
          <div className='section-window'>
               <div className={'section-container-portals ' + marginLeft + marginRight + containerWidth}>

                    <div className='portals-close-container'>
                         <Close
                              handleClick={props.handleClose}
                              classes={"button-close-section"}
                         />
                    </div>
                    <div className='portals-leftcolumn'>
                         <div className='portals-title'>
                              Pussykrew
                         </div>

                         <div className='portals-description'>
                              'Serpent of Self'
                         </div>

                         <div className='portals-description2'>
                              This project is hosted on an{' '}
                              <a href='https://athenaeum.blue/' target='_blank' className='portals-link'>external website.</a>
                         </div>

                         <a href='https://athenaeum.blue/' target='_blank'>
                              <img src='./images/portals/pussykrew.png' className='portals-image'></img>
                         </a>


                    </div>

                    <div className='portals-rightcolumn'>
                         Ewelina Aleksandrowicz and Andrzej Wojtas are Polish-born / NYC-based award-winning Directors and Artists, working under the moniker Pussykrew. Creating captivating gaming experiences, arthouse videos, immersive installations, and sensual sculptures, Pussykrew explores spaces in between digital and physical realms, blending fluid identities and futuristic landscapes through experimentation with real-time animation, photogrammetry, and VR sculpting. Hailing from the club culture, with a background in fine art, film, fashion, and tech, Pussykrew synthesizes their unique sensitivity and distinctive style to create significant influential art pieces.
                         <br></br>
                         <br></br>
                         At Rewire 2021 they present ‘Serpent of Self’, an interactive film written in collaboration with AI. The work is a form of immersive gaming odyssey, that explores themes of materiality, non-binary dreams, mysticism, primal forces of nature, and ritual. Through a collaborative machine learning process, by deconstructing past, present, and future, Pussykrew creates new narratives and mythologies driven by folklore and speculative fiction. They are trying to find new ways of seeing human/non-human experiences.
                    </div>

               </div>
          </div>

     )
}

