import classes from '../pages/timetable.scss'
import classes2 from './prismic.scss'
import React, { useRef, useCallback, useEffect, useState } from 'react'
import Prismic from '@prismicio/client'
import Close from "../ui/CloseButton";

import { RichText } from 'prismic-reactjs';

const apiEndpoint = 'https://rewirefestival.cdn.prismic.io/api/v2'


export default function PrismicSection(props) {

    const [body, setBody] = useState([]);

    useEffect(() => {
        async function fetchData() {
            const type = 'artist'
            Prismic.client(apiEndpoint).getApi().then(api => {
                return api.getByUID(type, props.uid, { lang: 'en-gb', fetchLinks: ['artist.tags', 'artist.image'] })
            }).then(res => {
                if (res !== undefined){
                    setBody(res.data.shorttext)
                } 
            })
        }
        fetchData()
    }, [props.uid])
    // Custom hooks

    return (  
            <div>
                <div className='prismic-text-section'>
                    {RichText.render(body)}
                </div>
            </div>  
    )
}