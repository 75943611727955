import React, { useEffect, useState } from 'react'


import Slider from './Slider'

// Ui
import DonateButton from "../ui/DonateButton";
import ChatEmbed from '../chat/ChatEmbed'
import TimetableButton from "../ui/TimetableButton";
import Timetable from "../pages/Timetable";
import Footer from '../Footer';
import Profile from '../Profile';
import FlashMessage from '../ui/Flash';
import Welcome from '../pages/Welcome';
import Overview from '../pages/Overview';
import Community from '../pages/Community';
import WelcomeMessage from '../pages/WelcomeMessage';


import OverviewButton from '../ui/OverviewButton';
import Logo from '../ui/Logo';
import SectionWindow from '../window/Window';
import VideoDialog from '../videoOnDemand/VideoDialog'

export default function Scene(props) {

     // 1, 2 - video sections
     // 3 - radio
     // 4, 5, 6, 7, 8, 9, 10 - portals
     // 11 - videos on demand
     const [openedSection, setOpenedSection] = useState(0);
     const [inWindow, setInWindow] = useState(false);

     // 1 - welcome
     // 2 - overview, ?, FAQ
     // 3 - profile
     // 4 - timetable
     // 6-9 - videos on demand
     const [openedDialog, setOpenedDialog] = useState(0);
     const [array, setArray] = useState(0);
     const [day, setDay] = useState(0);
     const [showDialog, setShowDialog] = useState(true);

     const [showChat, setShowChat] = useState(true);
     const [showAvatars, setShowAvatars] = useState(true);
     const [positionPacked, setPositionPacked] = useState(true);


     useEffect(() => {
          if (props.firstTimeHere) {
               setOpenedDialog(1);
          }
     }, [])

     // Controling Fullscreen Sections
     const handleOpenedSection = (i) => {
          setOpenedDialog(0);

          if (i === 0) {
               setOpenedSection(0);
               setInWindow(false);
          } else {
               setOpenedSection(i);
               setInWindow(true);
          }
     }

     const handleCloseSectionVod = () => {
          setOpenedSection(0);
          setShowDialog(true);
          setInWindow(false);
     }

     const handleOpenedSectionVod = (array, day) => {
          setArray(array);
          setDay(day);
          setShowDialog(false);

          setOpenedSection(11);
          setInWindow(true);
     }




     return (


          <div>
               <Logo classes={'logo logo-scene'} />

               {openedDialog === 1 ? <Welcome handleClick={() => setOpenedDialog(0)} handleFAQ={() => setOpenedDialog(2)} /> : ""}

               <OverviewButton handleClick={() => setOpenedDialog(2)} />
               {openedDialog === 2 ?
                    <Overview handleClick={() => setOpenedDialog(0)} />
                    : null}

               <TimetableButton handleClick={() => setOpenedDialog(4)} />
               {openedDialog === 4 ?
                    <Timetable handleClick={() => setOpenedDialog(0)} />
                    : null}

               {openedDialog === 3 ?
                    <Profile
                         user={props.user}
                         characterValues={props.characterValues}
                         handleUserUpdate={props.handleUserUpdate}
                         handleProfileClose={() => setOpenedDialog(0)}
                    />
                    : null}

               {openedDialog >= 6 && openedDialog <= 9 && showDialog ?
                    <VideoDialog
                         handleClose={() => setOpenedDialog(0)}
                         openedDialog={openedDialog}
                         handleOpenedSectionVod={(array, day) => handleOpenedSectionVod(array, day)}

                    />
                    :
                    null
               }

               {openedDialog === 10 ?
                    <Community handleClick={() => setOpenedDialog(0)}/>
                    :
                    null
               }

               {openedDialog === 11 ?
                    <WelcomeMessage handleClick={() => setOpenedDialog(0)}/>
                    :
                    null
               }


               {(props.flash !== "" && props.flash !== null) ?
                    <FlashMessage message={props.flash} flashPurge={props.flashPurge} />
                    : null}

               {showChat && window.innerWidth > 600 && openedSection !== 9 ?
                    <div className="chat border">
                         <ChatEmbed />
                    </div>
                    : null}


               {openedSection !== 0 ?
                    <SectionWindow
                         handleClose={() => handleOpenedSection(0)}
                         handleCloseSectionVod={() => handleCloseSectionVod()}
                         array={array}
                         day={day}
                         setShowSecretContent={() => setShowSecretContent(true)}
                         section={openedSection}
                         muted={props.muted}
                         audio={props.audio}
                         videos={props.videos}
                         showChat={showChat}
                         cliffordSage={props.cliffordSage}
                    />
                    : null}

               <Slider
                    user={props.user}
                    handleUserUpdate={props.handleUserUpdate}
                    handleOpenedSection={handleOpenedSection}
                    handleOpenedDialog={(i) => setOpenedDialog(i)}
                    handlePositionPacking={(bool) => setPositionPacked(bool)}
                    positionPacked={positionPacked}
                    showChat={showChat}
                    showAvatars={showAvatars}
                    muted={props.muted}
                    activePortals={props.activePortals}
                    videoOnDemand={props.videoOnDemand}
                    audio={props.audio}
                    inWindow={inWindow}
                    videos={props.videos}
               />


               <DonateButton history={props.history} inWindow={inWindow} />

               <Footer
                    openedSection={openedSection}
                    user={props.user}
                    muted={props.muted}
                    inWindow={inWindow}
                    handleProfileOpen={() => setOpenedDialog(3)}
                    handleMute={props.handleMute}
                    handleChatToggle={() => setShowChat(!showChat)}
                    handleAvatarsToggle={() => setShowAvatars(!showAvatars)}
                    handleExit={props.handleExit}
               />

          </div>
     )



}