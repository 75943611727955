import React, { Suspense } from 'react'

import classes from "../profile.scss";
import classes2 from "./buttons.scss";

function ProfileButton(props) {
  return (
    <div className="button-basic button-profile" onClick={props.handleClick}>
      {props.user.displayName}
    </div>
  )
}


export default ProfileButton